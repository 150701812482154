import React from 'react'
import './Header.css'
import MemoLandTV from '../icons/MemoLandTV.svg'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Profile from './Profile'
import SearchRelated from './SearchRelated'

function Header({setKeyWord, setFilterId, setMoodGenreID, userInfo, setUserInfo, startSearch}) {


  return (
    <div className="header">
    <div className = "header-content">
      <Link to = '/'>
       <img src={MemoLandTV}  alt="Memo Land TV logo" id = "site-logo"/>
      </Link>
      <SearchRelated
        setKeyWord = {setKeyWord}
        setFilterId = {setFilterId}
        startSearch = {startSearch}
      />
      </div>
      <Profile
        userInfo = {userInfo}
        setUserInfo = {setUserInfo}
      />
    </div>
  );
}

export default Header;
