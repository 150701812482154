import React, {useState, useEffect} from 'react'
import '../AllVideos.css'
import axios from 'axios'
import Slide from '../slides/Slide'
import VideoInfo from '../slides/VideoInfo'
import PlBack from '../icons/PlBack.svg'
import Footer from '../header_footer/Footer'

const AllLatestVideos = ({slideClickHandler, displayCount, dataHeight, goBack})=> {

  const [allLatestVideos, setAllLatestVideos] = useState([])
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)

  const activeGenres = JSON.parse(localStorage.getItem('activeGenres'))


  const handleScroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if(scrollHeight - scrollTop === clientHeight){
      setPage(prev => prev + 1)
    }
  }

  useEffect(()=>{
    axios.post(`${process.env.REACT_APP_API_URL}/memoApp/latest/videos/${page}/`,
        { 'genres': activeGenres }
      ).then(res => {
        if (page > 1){
          setAllLatestVideos(prev => [...prev, ...res.data])
        } else {
          setAllLatestVideos(res.data)
        }
        setLoading(false)
        })
        .catch(error => {
          setMessage(error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message)
        })
  },[page])


  return (
        <div className = "allVideos-wrapper">
          <div className="all-playlist-videos">
              <div className="show-all-heading">
                  <h6>LATEST VIDEOS</h6>
                  <div className='sah-icons-wrapper'>
                      <div className="icon-wrapper" onClick={() => { goBack() }}> <img src={PlBack} alt="go back" /> </div>
                  </div>
              </div>
              <div className="all-playlist-videos-content" onScroll = {handleScroll}>
                {
                  loading ? <div>Loading...</div> :
                  message ? <h3>{message}</h3> :
                  allLatestVideos && allLatestVideos.length ?
                    allLatestVideos.map(
                        (myVideo, i) =>
                            <div
                                className={'single-video-wrapper'}
                                key={i}
                            >
                                <div className="single-video-bg"
                                >
                                    <Slide
                                      content={myVideo}
                                      displayCount={6}
                                      slideClickHandler = {slideClickHandler}
                                      width = {"9vw"}
                                      />
                                </div>
                                <div className = "name-edit-delete">
                                  <div style = {{width: "65%"}}>
                                    <VideoInfo data = {myVideo}/>
                                  </div>
                                </div>
                            </div>
                    ) : ''
                }
              </div>
          </div>
          <Footer />
        </div>
  )
}

export default AllLatestVideos
