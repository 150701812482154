import React, {useState, useEffect} from 'react'
import AddVideo from './AddVideo'
import RecentlyAdded from './RecentlyAdded'
import axios from 'axios'
import './AdminPage.css'


const AdminPage = ({userInfo, setActiveVideo}) => {

  const [videos, setVideos] = useState([])

  const [genres, setGenres] = useState([])
  const [moods, setMoods] = useState([])

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/genres/`)
      .then(res => {
        setGenres(res.data)
      })
      .catch(message => {
        console.log(message)
      })
  },[])

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/moods/`)
      .then(res => {
        setMoods(res.data)
      })
      .catch(message => {
        console.log(message)
      })
  },[])

  return(
    <>
      <div className = "first-section">
        <div className = "first-content">
          <AddVideo
          setVideos = {setVideos}
          genres = {genres}
          moods = {moods}
          userInfo = {userInfo}
        />
        </div>
       </div>
       <div className = "second-section">
        <div className = "second-content">
          <RecentlyAdded
          videos = {videos}
          setVideos = {setVideos}
          genres = {genres}
          moods = {moods}
          userInfo = {userInfo}
          setActiveVideo = {setActiveVideo}
         />
        </div>
       </div>
    </>
  )
}

export default AdminPage
