import React from 'react'
import axios from 'axios'
import Close from '../icons/close.svg'

const MemberValuesContent = ({memberValues, memberIDs, setMemberIDs, addMemberHandler, member, setMembersTrigger, setMembers, setMember, setMemberValues}) => {

  //from similar members chooses one, or adds new one
  const chooseHandler = (value) => {
    if (!memberIDs.includes(value.id)){
      setMembers((prev)=>{ return[...prev, value] })
      setMemberIDs((prev)=>{ return[...prev, value.id] })
      setMember('')
      setMemberValues([])
      setMembersTrigger(false)
    } else {
      setMemberValues([])
      setMembersTrigger(false)
      alert('member is already added')
    }
  }


  const editHandler = (value) => {
    if (!memberIDs.includes(value.id)){
      axios.put(`${process.env.REACT_APP_API_URL}/memoApp/editMember/${value.id}/`,
        {
          'name' : member
        },
        {
          headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
          }
        }
      )
      .then(res =>
        {
          setMembers((prev)=>{ return[...prev, res.data] })
          setMemberIDs((prev)=>{ return[...prev, res.data.id] })
          setMember('')
          setMembersTrigger(false)
          setMemberValues([])
        }
      )
      .catch(message => {
        console.log(message)
        }
      )
    } else {
      alert('member is Already Added')
    }
  }



  return(
    <div  className = "members-wrapper">
        <div className = "close-icon">
         <img src = {Close} onClick = {()=>{setMembersTrigger(false)}}/>
        </div>
        <div className = "membersHeader">Did You Mean</div>
      <div  className = "members">
        {
          memberValues.map(value =>
            <div key = {value.id} className = "modal-member">
              <div>{value.name} </div>
              <div className = "member-choose">
                <button className = "modal-button" onClick = {()=>chooseHandler(value)}>Choose</button>
                <button className = "modal-button" onClick = {()=>editHandler(value)}>Yes, but keep New One</button>
              </div>
            </div>
          )
        }
        </div>
        <button className = "modal-button"
            onClick = {(e) =>{
                  e.preventDefault();
                  addMemberHandler(1);
                  setMemberValues([])
                  setMember('');
                  setMembersTrigger(false)
                }}>No, Add New One
        </button>
    </div>
  )
}

export default MemberValuesContent
