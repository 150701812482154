import React, {useRef, useEffect} from 'react'
import ReactDom from 'react-dom'
import './Modal.css';


const ModalOverlay = ({content, trigger, setTrigger}) => {
  const modalRef = useRef()


  return (trigger) ? (
    <div className = "modal" ref = {modalRef}>
      <div className = "modalContent">
        {content}
      </div>
    </div>
  ) : ""
}

const Modal = ({content, trigger, setTrigger}) => {
  return (
    <>
      {ReactDom.createPortal(
        <ModalOverlay
          content = {content}
          trigger = {trigger}
          setTrigger = {setTrigger}
        />,
        document.getElementById('overlay-root')
        )
      }
    </>
  )
}

export default Modal


/* <button className = 'closeBtn' onClick = {()=>setTrigger(false)}>close</button> */
