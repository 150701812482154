import React, { useRef, useState, useEffect } from 'react'
import Footer from './header_footer/Footer'
import './Home.css'
import Genres from './genres/Genres'
import LatestVideos from './latestVideos/LatestVideos'
import Playlist from './playlist/Playlist'
import Playlists from './playlists/Playlists'
import RelatedVideos from './relatedVideos/RelatedVideos'
import SearchResult from './searchResults/SearchResult'
import VideoPlayer from './videoPlayler/VideoPlayer'
import OtherPlaylists from './playlists/OtherPlaylists'
import WeeksPopular from './popular/WeeksPopular'
import MonthPopular from './popular/MonthPopular'
import CuAnalyze from './cuAnalyze/CuAnalyze'
import Modal from './modal/Modal'
import ResetPasswordConfirm from './user/ResetPasswordConfirm'
import UserActivate from './user/UserActivate'


export default function Home({playing, playlistVideos, analyzeActive, setAnalyzeActive, showAllSearchPath, analyzed, setAnalyzed, setPlaylistVideos, searchResults, userInfo, setPlaying, setSearchParams, setActiveVideo, activeData, setActiveData, activePlaylist, setActivePlaylist, goHomePlaylist, activeVideo,showAllClickHandler, slideClickHandler}) {

    const playerRef = useRef(null)

    //trigger, in case, customer needs to activate their account, or confirm password
    const [tokensTrigger, setTokensTrigger] = useState(false)
    //content of modal in case customer needs to activate their account, or confirm password
    const [content, setContent] = useState()

    // Latest videos
    const [latestVideos, setLatestVideos] = useState([])
    // Latest Videos filtered by Genres
    const [filteredLatestVideos, setFilteredLatestVideos] = useState()

    const [playlists, setPlaylists ] = useState([])

    const [currentTime, setCurrentTime] = useState(0)


    const handleReWind = () => {
      setAnalyzed(true)
      setCurrentTime(Math.round(playerRef.current.getCurrentTime()))
    }


    useEffect(()=>{
      if(localStorage.getItem('param')){
        const param = localStorage.getItem('param')
        if (param === '1'){
          setTokensTrigger(true)
          setContent(<UserActivate
            setTokensTrigger = {setTokensTrigger}
          />)
        } else if (param === '2'){
          setTokensTrigger(true)
          setContent(<ResetPasswordConfirm
            setTokensTrigger = {setTokensTrigger}
          />)
        } else {
          console.log('none')
        }
      }
    }, [])

    return (
        <>
            <div className="app-first-section">
              <div className = "app-first-content">
                <Modal
                  trigger = {tokensTrigger}
                  setTrigger = {setTokensTrigger}
                  content = {
                    content
                    }
                />
                <VideoPlayer
                  userInfo = {userInfo}
                  activeVideo = {activeVideo}
                  playing = {playing}
                  activeData = {activeData}
                  setActiveVideo = {setActiveVideo}
                  playlists = {playlists}
                  playlistVideos = {playlistVideos}
                  setPlaylistVideos = {setPlaylistVideos}
                  activePlaylist = {activePlaylist}
                  setPlaylists = {setPlaylists}
                  setCurrentTime = {setCurrentTime}
                  playerRef = {playerRef}
                  handleReWind = {handleReWind}
                  analyzeActive = {analyzeActive}
                  setAnalyzeActive = {setAnalyzeActive}
                  setAnalyzed = {setAnalyzed}
                  setPlaying = {setPlaying}
               />
               {
                analyzed && 
                  <CuAnalyze
                  activeVideo={activeVideo}
                  currentTime = {currentTime}
                  analyzeActive = {analyzeActive}
                  analyzed = {analyzed}
                  setAnalyzeActive = {setAnalyzeActive}
                />
               }
                {activePlaylist && playlistVideos && <Playlist
                  userInfo = {userInfo}
                  showAllPath = {userInfo && userInfo.id === activePlaylist.user ? `myplaylist/${activePlaylist.id}/${activePlaylist.name}` : `playlist/${activePlaylist.id}/${activePlaylist.name}`}
                  showAllClickHandler={showAllClickHandler}
                  activePlaylist = {activePlaylist}
                  setActiveVideo = {setActiveVideo}
                  playlistVideos = {playlistVideos}
                  setPlaylistVideos = {setPlaylistVideos}
                  displayCount={4}
                  setPlaying = {setPlaying}
                  // sCWHeight={8.2}
                  sCWHeight={12}
                  slideClickHandler={slideClickHandler}
                  />}
                {
                  userInfo && <Playlists
                  userInfo = {userInfo}
                  showAllPath={`playlists/${userInfo.id}`}
                  activePlaylist = {activePlaylist}
                  slideClickHandler = {slideClickHandler}
                  setActivePlaylist = {setActivePlaylist}
                  setActiveData = {setActiveData}
                  showAllClickHandler={showAllClickHandler}
                  playlists={playlists}
                  setPlaylists = {setPlaylists}
                  setPlaylistVideos = {setPlaylistVideos}
                  setPlaying = {setPlaying}
                  setActiveVideo = {setActiveVideo}
                  goHomePlaylist={goHomePlaylist}
                  setSearchParams = { setSearchParams }
                  setAnalyzed = {setAnalyzed}
                  setAnalyzeActive = {setAnalyzeActive}
                  displayCount={6}
                  sCWHeight={9}
                 />
                }
                <OtherPlaylists
                  userInfo = {userInfo}
                  showAllPath='all/playlists'
                  activePlaylist = {activePlaylist}
                  slideClickHandler = {slideClickHandler}
                  setActivePlaylist = {setActivePlaylist}
                  showAllClickHandler={showAllClickHandler}
                  setPlaylistVideos = {setPlaylistVideos}
                  setActiveVideo = {setActiveVideo}
                  setActiveData = {setActiveData}
                  setPlaying = {setPlaying}
                  setSearchParams = { setSearchParams }
                  goHomePlaylist={goHomePlaylist}
                  setAnalyzed = {setAnalyzed}
                  setAnalyzeActive = {setAnalyzeActive}
                  displayCount={6}
                  sCWHeight={11}
                />
              </div>
            </div>
            <div className = "app-second-section">
              <div className = "app-second-content">
                <Genres
                  latestVideos = {latestVideos}
                  setFilteredLatestVideos = {setFilteredLatestVideos}
                  />
                <LatestVideos
                  showAllPath="latest-video"
                  showAllClickHandler={showAllClickHandler}
                  displayCount={4}
                  sCWHeight={12}
                  slideClickHandler={slideClickHandler}
                  latestVideos = {latestVideos}
                  setFilteredLatestVideos = {setFilteredLatestVideos}
                  filteredLatestVideos = {filteredLatestVideos}
                  setLatestVideos = {setLatestVideos} />
                <RelatedVideos
                  showAllPath={`related-video/${activeVideo.id}`}
                  showAllClickHandler={showAllClickHandler}
                  videoID = {activeVideo.id}
                  displayCount={6}
                  sCWHeight={9}
                  slideClickHandler={slideClickHandler}
                  />
                <WeeksPopular
                  showAllPath="popular-video"
                  showAllClickHandler={showAllClickHandler}
                  displayCount={4}
                  sCWHeight={12}
                  slideClickHandler={slideClickHandler} />
                <MonthPopular
                  showAllPath="month-popular-video"
                  showAllClickHandler={showAllClickHandler}
                  displayCount={6}
                  sCWHeight={9}
                  slideClickHandler={slideClickHandler} />
                <SearchResult
                  showAllPath = { showAllSearchPath }
                  displayCount={6}
                  sCWHeight={9}
                  slideClickHandler={slideClickHandler}
                  showAllClickHandler = {showAllClickHandler}
                  searchResults = {searchResults}
                   />
              </div>
              <Footer/>
            </div>
        </>
    )
}
