import React, {useRef, useState, useEffect} from 'react'
import './VideoPlayer.css'
import ReactPlayer from 'react-player'
import CommentSection  from '../comments/CommentSection'
import VideoNameAndActions from './VideoNameAndActions'

export default function VideoPlayer({analyzeActive, setAnalyzed, setAnalyzeActive, setActiveVideo, activeVideo, activeData, handleReWind, playing, userInfo, playlists, playlistVideos, setPlaylistVideos, activePlaylist, setPlaylists, playerRef }) {

  let videoRef = useRef()
  // const playerRef = useRef(null)
  const [commentActive, setCommentActive] = useState(false)

  useEffect(() => {
        videoRef.current && videoRef.current.scrollIntoView({ behavior: "smooth" })
    }, [activeVideo])

  const endHandler = () => {
    if (activeData && activeData.length){
      let index = activeData.findIndex(data => data === activeVideo);
      if(index < activeData.length-1){
        setActiveVideo(activeData[index+1])
        setAnalyzed(false)
        setAnalyzeActive(false)
      }
    }
  }


  const startAnalyze = () => {
        const identifier = setTimeout(() => {
          setAnalyzeActive(true)
          setAnalyzed(true)
        }, 3000);

        return () => {
            clearTimeout(identifier)
        }
  }


  return (
      <div className='player-wrapper' ref = {videoRef}>
        <div className = {commentActive ? "player-video withComments" : "player-video"}>
          <ReactPlayer
            ref = {playerRef}
            className='react-player'
            controls
            url={activeVideo.link}
            onEnded = {endHandler}
            onStart = {startAnalyze}
            playing={playing}
            config={{
              youtube: {
                playerVars : {
                  controls: false,
                  rel: false,
                  more_videos: false
                }
              }
              }}
            width="100%"
            height={commentActive ? "27.25vw" : "29vw"}
          />
          <VideoNameAndActions  
              userInfo = {userInfo}
              playlists = {playlists}
              playlistVideos = {playlistVideos}
              setPlaylistVideos = {setPlaylistVideos}
              activeVideo = {activeVideo}
              activePlaylist = {activePlaylist}
              setPlaylists = {setPlaylists}
              setCommentActive = {setCommentActive}
              commentActive = {commentActive}
              handleReWind = {handleReWind}
              analyzeActive = {analyzeActive}
            />
          </div>
          {
            commentActive &&
            <CommentSection
              userInfo = {userInfo}
              videoID = {activeVideo.id}
              setCommentActive = {setCommentActive}
             />
          }
        </div>
  )

}

// && videos && videos.length
