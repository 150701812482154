import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
import Modal from '../modal/Modal'
import MemberValuesContent from './MemberValuesContent'
import Add from '../icons/Add_S.svg'
import deleteMember from '../icons/delete.svg'

const AddMember = ({members, setMember, userInfo, setMembers, member, memberIDs, setMemberIDs, label}) => {

  const [message, setMessage] = useState()
  const [memberValues, setMemberValues] = useState([])
  const [membersTrigger, setMembersTrigger] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [isMemberContentActive, setIsMemberContentActive] = useState(false)
  const membersRef = useRef()

  //adds new member to database and in video info
  const addMemberHandler = (strict) =>{
    if (member.length && member !== member.length*' ') {
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/addMember/`,
        {
          'name' : member, 'strict' : strict
        },
        {
          headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
           }
        }
      ).then(res =>
        {
          if(res.data.id){
            if (!memberIDs.includes(res.data.id)){
              setMembers((prev)=>{ return[...prev, res.data] })
              setMemberIDs((prev)=>{ return[...prev, res.data.id] })
              // setMemberInputActive(false)
              setMember('')
            } else {
              alert('member is already added')
            }
          }
          else
          {
            setMemberValues(res.data)
            setMembersTrigger(true)
            setIsMemberContentActive(false)
          }
        }
      )
      .catch(message => {
        setMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      })
    }
    else {
      alert('Member name Is Empty')
    }
  }

  //current search results according to current input value 
  const handleChange = (e) => {
    e.preventDefault()
    setIsMemberContentActive(true)
    setMember(e.target.value)
    if(e.target.value.length !== 0){
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/getMembers/${e.target.value}/`,
        {
          headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
           }
        }
      ).then(res =>{
        setFilteredData(res.data)
      }
      )
      .catch(message => {
        setMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      })
    } else {
      setFilteredData([])
    }

  }

  //choses value from the values of current search
  const chooseHandler = (value) => {
    if (!memberIDs.includes(value.id)){
      setMembers((prev)=>{ return[...prev, value] })
      setMemberIDs((prev)=>{ return[...prev, value.id] })
      setMember('')
      setFilteredData([])
      // setMemberInputActive(false)
    } else {
      alert('member is already added')
    }
  }


  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (membersRef == null || membersRef.current == null || !membersRef.current.contains(event.target)){
        setIsMemberContentActive(false)
      }
    })
  })


  return(
    <>
      <div className = "label-members">
        <label style = {{padding:"0 0.5rem"}}>{label}</label>
        {
          members && members.length ?
          members.map(member =>
            <div key = {member.id} className = "member">
              <div className = "member-name">
                {member.name}
              </div>
              <img
                src = {deleteMember}
                onClick = {(e) => {
                    e.preventDefault();
                    setMembers((prev) => prev.filter(memb => memb.id !== member.id));
                    setMemberIDs((prev)=>prev.filter(membID => membID !== member.id))
                  }
                }
              />
            </div>
          ) : ''
        }
      </div>
      {
        <div className = "input-wrapper">
          <input type = "text" value = {member} onChange = {handleChange}/>
          {
              isMemberContentActive && filteredData && filteredData.length ?
              <div className = "addVideoDropdown" ref = {membersRef} style = {{ position:"absolute", marginTop:"2vw"}}>
                <div className = "addVideoDropdown-content">
                  {
                    filteredData.map((member) => (
                        <div
                          className = "addVideoDropdown-item"
                          key = {member.id}
                          // className = "dataItem"
                          onClick = {()=>chooseHandler(member)}
                        >{member.name}</div>
                    ))
                  }
                </div>
              </div>: ''
          }
          <img src = {Add} onClick = {(e) =>{e.preventDefault(); addMemberHandler(0)} }/>
        <Modal
          trigger = {membersTrigger}
          setTrigger = {setMembersTrigger}
          content = {
            <MemberValuesContent
              userInfo = {userInfo}
              memberValues = {memberValues}
              addMemberHandler = {addMemberHandler}
              member = {member}
              setMembersTrigger = {setMembersTrigger}
              setMembers = {setMembers}
              setMember = {setMember}
              setMemberValues = {setMemberValues}
              members = {members}
              memberIDs = {memberIDs}
              setMemberIDs = {setMemberIDs}
            />
          }
        />
        </div>
      }
    </>
  )
}

export default AddMember
