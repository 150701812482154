import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import '../AllVideos.css'
import axios from 'axios'
import Slide from '../slides/Slide'
import Footer from '../header_footer/Footer'
import VideoInfo from '../slides/VideoInfo'
import PlBack from '../icons/PlBack.svg'

const AllSearchResults = ({slideClickHandler, goBack, displayCount, dataHeight}) => {

  const [allSearchResults, setAllSearchResults] = useState([])
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const { filterId, moodGenreID, keyWord } = useParams()
  const [page, setPage] = useState(1)


  const handleScroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if(scrollHeight - scrollTop === clientHeight){
      setPage(prev => prev + 1)
    }
  }

  useEffect(()=>{
    if(filterId !== 3 && filterId !== 5 && keyWord.length === 0 ){
      console.log('please, fill search bar')
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/searchResults/${page}/`,
        { 'filterId' : filterId, 'moodGenreID': moodGenreID, 'keyWord': keyWord }
        ).then(res => {
          if (page > 1){
            setAllSearchResults(prev => [...prev, ...res.data])
          } else {
            setAllSearchResults(res.data)
          }
          setLoading(false)
        })
      .catch(error => {
        setMessage(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message)
      })
    }
  },[keyWord, filterId, moodGenreID, page])


  return (
    <div className = "allVideos-wrapper">
        <div className = "all-playlist-videos">
          <div className="show-all-heading">
              <h6>SEARCH REASULTS</h6>
              <div className='sah-icons-wrapper'>
                  <div className="icon-wrapper" onClick={() => { goBack() }}> <img src={PlBack} alt="go back" /> </div>
              </div>
          </div>
          <div className = "all-playlist-videos-content" onScroll = {handleScroll}>
            {
              loading ? <div>Loading...</div> :
              allSearchResults && allSearchResults.length ?
              allSearchResults.map(
                    (myVideo, i) =>
                        <div
                            className={'single-video-wrapper'}
                            key={i}
                        >
                            <div className="single-video-bg"
                                style={{ height: dataHeight + 'vw' }}
                                // onClick={() => { if (slideClickHandler) slideClickHandler(myVideo); goBack() }}
                            >
                                <Slide
                                  content={myVideo}
                                  displayCount={displayCount }
                                  // onClick={() => { if (slideClickHandler) slideClickHandler(myVideo); goBack() }}
                                  slideClickHandler = {slideClickHandler}
                                  width = {"9vw"}
                                  />
                            </div>
                            <div className = "name-edit-delete">
                              <div style = {{width: "65%"}}>
                                <VideoInfo data = {myVideo}/>
                              </div>
                            </div>
                        </div>
                ) : ''
            }
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default AllSearchResults
