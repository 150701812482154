import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import '../AllVideos.css'
import axios from 'axios'
import AllPlaylistVideosComponent from './AllPlaylistVideosComponent'
import Edit from '../icons/Edit.svg'
import goBackIcon from '../icons/PlBack.svg'
import Footer from '../header_footer/Footer'


const AllMyPlaylistVideos = ({userInfo, activePlaylist, slideClickHandler, playlistVideos, setPlaylistVideos, displayCount, dataHeight, goBack})=> {

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const [deleteActive, setDeleteActive] = useState(false)
  const { playlistID, playlistName } = useParams()
  const [myPlaylists, setMyPlaylists] = useState([])

  useEffect(()=>{
    if (userInfo){
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/users/myPlaylists/${userInfo.id}/`,
        {
          headers:{
            'Content-type':'application/json',
            }
        }
      )
      .then(res => {
          if (res.data && res.data.length){
            setMyPlaylists(res.data)
          } else {
            setMessage('No Playlists Yet')
          }
          setLoading(false)
      })
      .catch(error => {
          setMessage(error.response && error.response.data.message
          ? error.response.data.message
          : error.message)
      })
    }
  },[userInfo])


  const config = {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
     }
  }

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/users/myPlaylistVideos/${playlistID}/`,
    config
    )
      .then(res => {
        if (res.data){
          setPlaylistVideos(res.data)
        }
        setLoading(false)
      })
      .catch(error => {
        setMessage(error.response && error.response.data.message
        ? error.response.data.message
        : error.message)
      })
    },[playlistID])

  const editHandler = (e) => {
    e.preventDefault()
    setDeleteActive(!deleteActive)
  }


  return (
      <div className = "allVideos-wrapper">
        {
          loading ? <div>Loading...</div> :
          message ? <h3>{message}</h3> :
          playlistVideos && playlistVideos.length ?
            <div className="all-playlist-videos">
                <div className="show-all-heading">
                    <h6>{playlistName}</h6>
                    <div className='sah-icons-wrapper'>
                      <div className = "header-icon-wrapper" >
                        <div onClick = {goBack}>
                          <img src = {goBackIcon} alt = "back"/>
                        </div>
                          { userInfo &&
                            <div onClick = {()=>setDeleteActive(!deleteActive)}>
                              <img src = {Edit} alt = "edit"/>
                            </div>
                          }
                      </div>
                    </div>
                </div>
                <div className="all-playlist-videos-content">
                    {
                        playlistVideos.map(
                            (myVideo, i) =>
                                <div
                                    className={'single-video-wrapper'}
                                    key={i}
                                >
                                  <AllPlaylistVideosComponent
                                    userInfo = {userInfo}
                                    playlistID = {playlistID}
                                    displayCount = {displayCount}
                                    slideClickHandler = {slideClickHandler}
                                    myVideo = {myVideo}
                                    setPlaylistVideos = {setPlaylistVideos}
                                    playlistVideos = {playlistVideos}
                                    playlists = {myPlaylists}
                                    setPlaylists = {setMyPlaylists}
                                    activePlaylist = {activePlaylist}
                                    dataHeight = {dataHeight}
                                    deleteActive = {deleteActive}
                                  />
                                </div>
                        )
                    }
                </div>
            </div>
            : ''
        }
        <Footer/>
      </div>
  )
}

export default AllMyPlaylistVideos
