import React, {useState} from 'react'
import { Link, Navigate } from 'react-router-dom'
import axios from 'axios'
import close from '../icons/modalClose.svg'
import Login from '../user/Login'

const Register = ({userInfo, setUserInfo, setContent, setLoginModalTrigger}) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [message, setMessage] = useState()
  const [infoMessage, setInfoMessage] = useState()

  const config = {
    headers:{
      'Content-type':'application/json',
      'Accept': 'application/json'
    }
  }

  const continueWithGoogle = (e) => {
    e.preventDefault()
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}`,
      config
    ).then(res =>
      {
        window.location.replace(res.data.authorization_url)
      }
    ).catch(
       console.log('ok')
    )
  }


  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword){
      setMessage('Passwords do not match')
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/users/checkEmail/`,
        { 'email': email, 'index': 1},
        config
      ).then(response => {
        axios.post(`${process.env.REACT_APP_API_URL}/memoApp/auth/users/`,
          {'first_name': firstName, 'last_name': lastName, 'email': email, 'password': password, 're_password': confirmPassword},
          config
        )
          .then(response => {
            localStorage.setItem('param', 1)
            setInfoMessage('Activation Link Is sent to your email, Please, Check and confirm')
          })
          .catch(message => {
            setMessage(message.response && message.response.data.password
            ? message.response.data.password
            : "Something Went Wrong Please Try Again")
          })
      }
    ).catch(message =>{
      setMessage(message.response && message.response.data.detail
      ? message.response.data.detail
      : message.message)
    }
  )
    }
  }


  if (userInfo){
      return <Navigate to = '/'/>
    } else if(infoMessage && infoMessage.length) {
      return(
        <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setLoginModalTrigger(false)}/>
          </div>
         <div className = "message">{infoMessage}</div>
        </div>
      )
    } else {
      return (
          <div className = "login-form">
            <div className = "modal-close">
              <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setLoginModalTrigger(false)}/>
            </div>
              {message && <div className = "message">{message}</div>}

              <div className = "login-input-wrapper">
                <input className = "login-Input" type="text" value = {firstName} placeholder="First Name" onChange = {(e) => setFirstName(e.target.value)} />
              </div>

              <div className = "login-input-wrapper">
                <input className = "login-Input" type="text" value = {lastName} placeholder="Last Name" onChange = {(e) => setLastName(e.target.value)} />
              </div>

              <div className = "login-input-wrapper">
                <input className = "login-Input" type="email" value = {email} placeholder="Email" onChange = {(e) => setEmail(e.target.value)} />
              </div>

              <div className = "login-input-wrapper">
                <input className = "login-Input" type="password" value = {password} placeholder="Password" onChange = {(e) => setPassword(e.target.value)} />
              </div>

              <div className = "login-input-wrapper">
                <input className = "login-Input" type="password" value = {confirmPassword} placeholder="Confirm Password" onChange = {(e) => setConfirmPassword(e.target.value)} />
              </div>

              <div className = 'continue-google' onClick = {continueWithGoogle}>Continue with Google</div>

              <button className = "login-button" onClick={submitHandler}>Register</button>

              <div className = "move-to-register">Already have an account?
               <span onClick={()=>setContent(<Login 
                userInfo={userInfo}
                setUserInfo = {setUserInfo}
                setContent = {setContent}
                setLoginModalTrigger = {setLoginModalTrigger}
                />)}> Login</span>
              </div>
          </div>
      )
    }
}

export default Register

          // {loading && <Loader/>}
