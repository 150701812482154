import React, {useState} from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import close from '../icons/modalClose.svg'

const ResetPassword = ({userInfo, setLoginModalTrigger}) => {

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState()
    const [infoMessage, setInfoMessage] = useState()

    const config = {
      headers:{
        'Content-type':'application/json',
         "X-CSRFToken": '{{csrf_token}}'
       }
    }


    const submitHandler = (e) => {
      e.preventDefault()
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/users/checkEmail/`,
        { 'email': email, 'index': 0},
        config
      ).then(response =>{
        axios.post(`${process.env.REACT_APP_API_URL}/memoApp/auth/users/reset_password/`,
          { 'email': email},
          config
        )
          .then(response => {
            localStorage.setItem('param', 2)
            setInfoMessage('Activation Link Is Sent To Your Email, Please, Check And Confirm')
          })
          .catch(message => {
            setMessage("Something Went Wrong Please Try Again")
          })
      }
    ).catch(message =>{
      setMessage(message.response && message.response.data.detail
      ? message.response.data.detail
      : message.message)
    }
  )
    }


    if(userInfo){
      return <Navigate to = '/' />
    } else if( infoMessage ){
      return(
        <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setLoginModalTrigger(false)}/>
          </div>
         <div className = "message">{infoMessage}</div>
        </div>
      )
    } else {
      return(
        <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setLoginModalTrigger(false)}/>
          </div>
        {message && <div className = "message">{message}</div>}
          <div className = "move-to-register">Enter Your Email</div>
          <div className = "login-input-wrapper" data-validate="Enter valid email">
            <input className = "login-Input" type="email" value = {email} placeholder="Email" onChange = {(e) => setEmail(e.target.value)} />
          </div>
          <button className = "login-button" onClick = {submitHandler}>Confirm</button>
        </div>
      )
    }
}

export default ResetPassword
