import React, {useState} from 'react'
import DeleteRecent from '../icons/delete.svg'
import Edit from '../icons/Edit.svg'
import Modal from '../modal/Modal'
import axios from 'axios'
import VideoEdit from './VideoEdit'
import Close from '../icons/close.svg'
import ON from '../icons/ON.png'

const SingleVideo = ({userInfo, video, genres, moods, setVideos, setAdminSearchResults, setActiveVideo}) => {

    const [editVideoTrigger, setEditVideoTrigger] = useState(false)
    const [modalTrigger, setModalTrigger] = useState(false)
    const [content, setContent] = useState()


    const deleteHandler = (data) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/memoApp/deleteVideo/${data.id}/`,
          {
            headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
             }
          }
        ).then(res => {
          setAdminSearchResults((prev)=>prev.filter(video => video.id !== data.id))
          setVideos((prev)=>prev.filter(video => video.id !== data.id))
          setModalTrigger(false)
        })
        .catch(error => {
          console.log(error)
        })
      }



    const setMainVideo = (data) => {
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/setMainVideo/${data.id}/`,
      {
        headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
         }
      }
    ).then(res => {
      setActiveVideo(data)
      setContent(                
        <div className = "delContent">
        <div className = "close-icon">
          <img src = {Close} onClick = {()=>{setModalTrigger(false)}}/>
        </div>
        <h5>Video {'<<' + data.name + '>>'} is set as main video</h5>
        <button onClick = {()=>setModalTrigger(false)}>OK</button>
        </div>)
    })
    .catch(error => {
      console.log(error)
    })
    }

    return(
        <>
            <div className = "row">
                <div>{video.name}</div>
                <div className = "row-inside">
                <img src = {ON} 
                  onClick = {()=>{
                    setModalTrigger(true)
                    setContent(                
                      <div className = "delContent">
                      <div className = "close-icon">
                        <img src = {Close} onClick = {()=>{setModalTrigger(false)}}/>
                      </div>
                      <h5>Are You sure You Want to set {'<<' + video.name + '>>'} as main video?</h5>
                      <button onClick = {()=>setMainVideo(video)}>OK</button>
                      </div>)
                    }}/>
                <img src = {Edit} onClick = {()=>{setEditVideoTrigger(!editVideoTrigger)}} />
                <img src = {DeleteRecent} 
                  onClick = {() => {
                      setModalTrigger(true); 
                      setContent(                
                        <div className = "delContent">
                        <div className = "close-icon">
                          <img src = {Close} onClick = {()=>{setModalTrigger(false)}}/>
                        </div>
                        <h5>Are You sure You Want to delete {'<<' + video.name + '>>?'}</h5>
                        <button onClick = {()=>deleteHandler(video)}>OK</button>
                        </div>)
                    }}/>
                </div>
            </div>
            {
            editVideoTrigger && 
            <VideoEdit
                userInfo = {userInfo}
                video = {video}
                genres = {genres}
                moods = {moods}
                setEditVideoTrigger = {setEditVideoTrigger}
            />
            }
            <Modal
              trigger = {modalTrigger}
              setTrigger = {setModalTrigger}
              content = {content}
            />
         </>
    )
}

export default SingleVideo