import '../playlists/Playlists.css'
import Slider from '../slides/Slider'
import React, { useState, useEffect } from 'react';
import axios from 'axios'

export default function MonthPopular({showAllPath, showAllClickHandler, sCWHeight, displayCount, slideClickHandler }) {

    const [monthPopularVideos, setMonthPopularVideos] = useState([])
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState()

    useEffect(()=>{
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/monthPopular/${0}/`,
        {
          headers:{
            'Content-type':'application/json',
            'Accept': 'application/json'
           }
        }
      )
        .then(res => {
          setMonthPopularVideos(res.data)
          setLoading(false)
        })
        .catch(message => {
          setMessage(message.response && message.response.data.message
          ? message.response.data.message
          : message.message)
        })
      },[])


      let slideWrapperWidth = 9.5
      let slidePlusMargin = slideWrapperWidth + .6

  return (
    <>
      {
        loading ? <div>Loading...</div> :
        message ? <h3>{message}</h3> :
        monthPopularVideos && monthPopularVideos.length ?
        <div className="playlists">
          <Slider
            title = {"POPULAR THIS MONTH"}
            displayCount={displayCount}
            sliderContentWidth={(slideWrapperWidth + .8) * monthPopularVideos.length - .8}
            slideWrapperWidth={slideWrapperWidth}
            step={slidePlusMargin}
            sCWHeight={sCWHeight}
            tvData={monthPopularVideos}
            slideClickHandler={slideClickHandler}
            showAllPath={showAllPath}
            showAllClickHandler={showAllClickHandler}
          />
        </div>
        : ''
      }
    </>
  )
}
