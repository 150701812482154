import React, {useState} from 'react'
import './AddVideo.css'
import AddMember from './AddMember'
import axios from 'axios'
import Choose from '../icons/Choose.svg'



const VideoEdit = ({video, genres, moods, userInfo, setEditVideoTrigger}) => {

  const artIDs = video.artists.map(artist => artist.id)
  const compIDs = video.composers.map(composer => composer.id)

  const [name, setName] = useState(video.name)
  const [thumbnail, setThumbnail] = useState(video.thumbnail)
  const [duration, setDuration] = useState(video.duration)

  const [genre, setGenre] = useState(video.genre)
  const [mood, setMood] = useState(video.mood)

  const [artist, setArtist] = useState('')
  const [artistIDs, setArtistIDs] = useState(artIDs)
  const [artists, setArtists] = useState(video.artists)
  const [composer, setComposer] = useState('')
  const [composerIDs, setComposerIDs] = useState(compIDs)
  const [composers, setComposers] = useState(video.composers)

  const [isGenreActive, setIsGenreActive] = useState(false)
  const [isMoodActive, setIsMoodActive] = useState(false)
  const [message, setMessage] = useState('')



  const submitHandler = (e) => {
      e.preventDefault()
      setMessage('')
      if (!name.length || name === name.length*' '){
        alert('Title is Empty')
      } else if(artists.length === 0 && composers.length === 0){
        alert('Please, Add Member')
      } else if (artist.length) {
        alert('You Forgot To Add Artist')
      } else if (composer.length) {
        alert('You Forgot To Add Composer')
      } else if (!genre) {
        alert('Please, Add Genre')
      } else if (!mood) {
        alert('Please, Add Mood')
      } else if (!thumbnail.length || thumbnail === thumbnail.length*' ') {
        alert('Thumbnail is empty')
      } else if (duration === 0) {
        alert('Please, Enter duration')
      } else {
        axios.put(`${process.env.REACT_APP_API_URL}/memoApp/editVideo/${video.id}`,
          {
            'name' : name,
            'artistIDs' : artistIDs,
            'composerIDs' : composerIDs,
            'genre_id' : genre.id,
            'mood_id' : mood.id,
            'thumbnail' : thumbnail,
            'duration' : duration
          },
          {
            headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
             }
          }
        ).then(res =>
          {
            video.name = name
            video.artists = artists
            video.composers = composers
            video.duration = duration
            video.thumbnail = thumbnail
            video.genre = genre
            video.mood = mood
            setEditVideoTrigger(false)
          }
        )
        .catch(message => {
          setMessage(message.response && message.response.data.detail
          ? message.response.data.detail
          : message.message)
        })
        }
      }

  return (
    userInfo && userInfo.is_staff ?
    <>
      <div className = "edit-link">
        {video.link}
      </div>
      {message && <div className='edit-link' style = {{textDecoration:"none"}} >{message}</div>}
      <div className = "addVideo-form editVideo">
          <div className = "addVideo-component">
            <label>TITLE</label>
            <input type = "text" value = {name} onChange = {(e)=>{e.preventDefault(); setName(e.target.value)}} />
          </div>
          <div className = "addVideo-component">
            <label>MOOD</label>
            <div className = "addVideoDropdown">
              {
                mood ?
                <div className = "input-wrapper">
                 <div className = "addVideoDropdownBtn">{mood.name.toUpperCase()}</div> 
                 <img src = {Choose} onClick = {()=>setIsMoodActive(!isMoodActive)}/>
                </div>:
                <div className = "input-wrapper">
                  <div className = "addVideoDropdownBtn" >Choose One</div>
                  <img src = {Choose} onClick = {()=>setIsMoodActive(!isMoodActive)}/>
                </div>
              }
              {
                isMoodActive &&
                <div className = "addVideoDropdown-content">
                  {
                    moods && moods.length &&
                    moods.map(mood =>
                      <div
                        className = "addVideoDropdown-item"
                        key = {mood.id}
                        id = {mood.id}
                        onClick = {()=>{setMood(mood); setIsMoodActive(false)}}>
                          {mood.name.toUpperCase()}
                        </div>
                    )
                  }
                </div>
              }
            </div>
          </div>
          <div className = "addVideo-component">
            <AddMember
              userInfo = {userInfo}
              members = {artists}
              setMember = {setArtist}
              setMembers = {setArtists}
              member = {artist}
              memberIDs = {artistIDs}
              setMemberIDs = {setArtistIDs}
              label = {"ARTIST:"}
            />
          </div>
          <div className = "addVideo-component">
            <label>THUMBNAIL</label>
            <input value = {thumbnail} onChange = {(e)=>{e.preventDefault(); setThumbnail(e.target.value)}}/>
          </div>
          <div className = "addVideo-component">
            <AddMember
              userInfo = {userInfo}
              members = {composers}
              setMember = {setComposer}
              setMembers = {setComposers}
              member = {composer}
              memberIDs = {composerIDs}
              setMemberIDs = {setComposerIDs}
              label = {"COMPOSER:"}
            />
          </div>
          <div className = "addVideo-component">
          <label>DURATION</label>
            <input value = {duration} onChange = {(e)=>{e.preventDefault(); setDuration(e.target.value)}}/> 
          </div>
          <div className = "addVideo-component">
            <label>GENRE</label>
            <div className = "addVideoDropdown">
              {
                genre ?
                <div className = "input-wrapper">
                  <div className = "addVideoDropdownBtn">{genre.name.toUpperCase()}</div> 
                  <img src = {Choose} onClick = {()=>setIsGenreActive(!isGenreActive)}/>
                </div>:
                <div className = "input-wrapper">
                  <div className = "addVideoDropdownBtn" >Choose One</div>
                  <img src = {Choose} onClick = {()=>setIsGenreActive(!isGenreActive)}/>
                </div>
             }
              {
                isGenreActive &&
                <div className = "addVideoDropdown-content">
                  {
                    genres && genres.length &&
                    genres.map(genre =>
                      <div
                        className = "addVideoDropdown-item"
                        key = {genre.id}
                        id = {genre.id}
                        onClick = {()=>{setGenre(genre); setIsGenreActive(false)}}>
                          {genre.name.toUpperCase()}
                        </div>
                    )
                  }
                </div>
              }
            </div>
          </div>
          <div className = "addVideo-component">
            <label style = {{color:"transparent"}}>Some</label>
            <button type = "submit" className = "addVideoBtn" onClick = {submitHandler}>SAVE</button>
          </div>
        </div>
    </>  : "You Don't Have Access to this page"
  )
}

export default VideoEdit
