import React, { useState } from 'react'
import axios from 'axios'
import close from '../icons/modalClose.svg'


const UserActivate = ({setTokensTrigger}) => {

  const [message, setMessage] = useState()
  const [infoMessage, setInfoMessage] = useState('')

  const uid = localStorage.getItem('uid')
  const token = localStorage.getItem('token')

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }


  const verify = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/memoApp/auth/users/activation/`,
      {'uid':uid, 'token':token},
      config
    ).then(response =>
      {
        setInfoMessage('User has been activated, You can sign in')
        localStorage.removeItem('uid')
        localStorage.removeItem('token')
        localStorage.removeItem('param')
      }
    ).catch(message =>
      setMessage("Something went wrong, please, try again")
    )
  }


    if(infoMessage && infoMessage.length){
      return(
        <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setTokensTrigger(false)}/>
          </div>
         <div className = "message">{infoMessage}</div>
        </div>
      )
    } else {
      return(
        <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} 
                onClick = {()=>{
                  localStorage.removeItem('param')
                  localStorage.removeItem('uid')
                  localStorage.removeItem('token')
                  setTokensTrigger(false)
                }
            }/>
          </div>
          {message && <div className = "message">{message}</div>}
          <div>
              <div className = "message">Verify Your Account</div>
              <button className = "login-button" onClick = {verify}>Verify</button>
          </div>
        </div>
      )
    }
}

export default UserActivate
