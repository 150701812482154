import React, {useState} from 'react'
import axios from 'axios'
import close from '../icons/modalClose.svg'

const ResetPasswordConfirm = ({setTokensTrigger}) => { 

  const uid = localStorage.getItem('uid')
  const token = localStorage.getItem('token')

  const [newPassword, setNewPassword] = useState('')
  const [reNewPassword, setReNewPassword] = useState('')
  const [infoMessage, setInfoMessage] = useState('')

  const [message, setMessage] = useState()

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }


  const body = {
    'uid': uid, 'token':token, 'new_password': newPassword, 're_new_password': reNewPassword
  }

  const submitHandler = () => {
    if(newPassword === reNewPassword){
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/auth/users/reset_password_confirm/`, body, config)
      .then(response =>
        {
          setInfoMessage('Password has been changed, You can sign in')
          localStorage.removeItem('uid')
          localStorage.removeItem('token')
          localStorage.removeItem('param')
        }
      ).catch(message => {
          setMessage(message.response && message.response.data.new_password
          ? message.response.data.new_password
          : "Something Went Wrong Please Try Again")
        })
    } else {
        setMessage('Passwords do not match')
    }

  }


   if(infoMessage && infoMessage.length){
      return(
        <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setTokensTrigger(false)}/>
          </div>
          <div className = "message">{infoMessage}</div>
        </div>
      )
    } else {
      return (
        <>
        <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} 
                onClick = {()=>{
                  localStorage.removeItem('param')
                  localStorage.removeItem('uid')
                  localStorage.removeItem('token')
                  setTokensTrigger(false)
                }
            }/>
          </div>
          {message && <div className = "message">{message}</div>} 
          <div className = "login-input-wrapper" data-validate="Enter valid email">
            <input type="password" className = "login-Input" value = {newPassword} placeholder="New Password" onChange = {(e) => setNewPassword(e.target.value)} />
          </div>
          <div className = "login-input-wrapper" data-validate="Enter valid email">
            <input type="password" className = "login-Input"  value = {reNewPassword} placeholder="Confirm Password" onChange = {(e) => setReNewPassword(e.target.value)} />
          </div>
          <button className = "login-button" onClick = {submitHandler}>Confirm</button>
        </div>
      </>
      )
    }

}

export default ResetPasswordConfirm


// return (
//   <>
//     {message && <div>{message}</div>}
//     <div data-validate="Enter valid email">
//       <span>Type New Password</span>
//       <input type="password" value = {newPassword} placeholder="New Password" onChange = {(e) => setNewPassword(e.target.value)} />
//       <span>ReType New Password</span>
//       <input type="password" value = {reNewPassword} placeholder="Confirm Password" onChange = {(e) => setReNewPassword(e.target.value)} />
//       <button onClick = {submitHandler}>Confirm</button>
//     </div>
//   </>
// )