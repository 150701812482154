import React, {useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import '../AllVideos.css'
import axios from 'axios'
import Slide from '../slides/Slide'
import Modal from '../modal/Modal'
import close from '../icons/modalClose.svg'
import edit from '../icons/videoEdit.svg'
import Delete from '../icons/videoDelete.svg'
import VideoInfo from '../slides/VideoInfo'


const AllPlaylistComponent = ({userInfo, playlist, setAllPlaylists, displayCount, editPlActive, setActivePlaylist, activePlaylist}) => {

  const [isActive, setIsActive] = useState(false)
  const [message, setMessage] = useState('')
  const [plName, setPlName] = useState(playlist.name)
  const [delTrigger, setDelTrigger] = useState(false)
  let editRef = useRef()
  const navigate = useNavigate()


  const playlistClickHandler = (data) => {
    setActivePlaylist(data)
    userInfo && userInfo.id === playlist.user ?
    navigate(`/myplaylist/${data.id}/${data.name}`):
    navigate(`/playlist/${data.id}/${data.name}`)
  }



  const deleteHandler = (data) =>{
    axios.delete(`${process.env.REACT_APP_API_URL}/memoApp/deletePlaylist/${data.id}/`,
       {
        headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
         }
      }
      ).then(response => {
          setAllPlaylists((prevArr) => prevArr.filter(item => item.id !== data.id))
          if (activePlaylist.id === data.id){
            setActivePlaylist(null)
          }
        })
        .catch(error => {
          setMessage(error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message)
        })
  }


  const editHandler = (data) => {
    setMessage('')
    setIsActive(true)
  }

  const saveHandler = (data) => {
    if (plName.trim() === playlist.name){
      setIsActive(false)
    }
    else if (plName.length && plName !== plName.length*' ') {
      axios.put(`${process.env.REACT_APP_API_URL}/memoApp/editPlaylist/${data.id}/`,
          {'playlistName': plName.trim() },
          {
           headers:{
             'Content-type':'application/json',
             'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
         }
        ).then(response => {
          setPlName(plName)
          playlist.name = plName
          setIsActive(false)
          })
          .catch(error => {
            setMessage(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message)
          })
        }
    else{
        alert('You forgot to choose playlist ')
    }
  }

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (editRef == null || editRef.current == null || !editRef.current.contains(event.target)){
        setIsActive(false)
      }
    })
  })

  return (
    <>
        <div className = "single-video-bg">
          <Slide
            content = {playlist}
            displaycount = {displayCount}
            slideClickHandler={playlistClickHandler}
            width = {"9vw"}
          />  
        </div>
        <div className = "name-edit-delete">
          {
            isActive ?
            <div ref = {editRef} className = "pl-video-edit">
              {message && <div>{message}</div>}
              <input type = 'name' value = {plName} onChange = {(e)=>setPlName(e.target.value)} />
              <div className = "buttons">
                <button onClick = {()=>setIsActive(false)}>Cancel</button>
                <button style = {{border: "0.1vw solid #9d17fb"}} onClick = {() => saveHandler(playlist)}>Save</button>
              </div>
            </div> :
            <div style = {{width: "65%"}}>
              <VideoInfo data = {playlist}/>
            </div>
          }
          {
          editPlActive &&
          <>
          { userInfo && userInfo.id === playlist.user &&
              <> 
                  <div className = "icon-wrapper" id = {playlist.id} >
                    <div onClick = {()=>editHandler(playlist)}>
                      <img src = {edit} alt = "edit"/>
                    </div>
                    <div onClick = {() => setDelTrigger(true)}>
                    <img src = {Delete} alt = "delete"/>
                    </div>
                    <Modal
                      trigger = {delTrigger}
                      setTrigger = {setDelTrigger}
                      content = {
                        <div className = "delContent">
                          <div className = "modal-close">
                            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setDelTrigger(false)}/>
                          </div>
                          <h5>Are You sure You Want to delete playlist {'<<'+playlist.name+'>>'}</h5>
                          <button onClick = {()=>deleteHandler(playlist)}>OK</button>
                        </div>
                      }
                    />
                  </div>
            </>
          }
          </>
        }
        </div>
        
    </>
  )
}

export default AllPlaylistComponent
