import React from 'react'
import Slide from "./Slide";
import "./Slides.css";
import VideoInfo from './VideoInfo'

export default function Slides({ displayCount, slideWrapperWidth, tvData, slideClickHandler, playlistSlideClickHandler, updateActivePlaylistId, goHomePlaylist }) {
    const sWRightSpaceClass = displayCount === 4 ? 'slide-wrapper-for-four' : 'slide-wrapper-for-six'
    return (
        <>
            { tvData && tvData.length ?
                tvData.map(
                    (data, i) =>
                        <div
                            className={'slide-wrapper ' + sWRightSpaceClass}
                            key={i}
                            style={{
                                width: `${slideWrapperWidth}vw`,
                                height: '100%'
                            }}
                            // onClick={() => { if (slideClickHandler) { slideClickHandler(data) } else if (updateActivePlaylistId && playlistSlideClickHandler && goHomePlaylist) { updateActivePlaylistId(data.id); playlistSlideClickHandler(data.id); goHomePlaylist(data.id) } }}
                        >
                            <Slide
                              tvData = {tvData}
                              content={data}
                              displayCount={displayCount}
                              slideClickHandler = {slideClickHandler}
                              width =  {"100%"}
//                              onClick={() => { if (slideClickHandler) { slideClickHandler(data) } else if (updateActivePlaylistId && playlistSlideClickHandler && goHomePlaylist) { updateActivePlaylistId(data.id); playlistSlideClickHandler(data.id); goHomePlaylist(data.id) } }}
                            />
                            <VideoInfo data = {data}/>
                        </div>
                )
                : ''
            }
        </>
    )
}
