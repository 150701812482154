import React, {useState, useEffect} from 'react'
import './CommentsSection.css'
import axios from 'axios'
import Comment from './Comment'
import close from '../icons/close.svg'
import AddComment from './AddComment'

const CommentSection = ({videoID, userInfo, setCommentActive}) => {

  const [comments, setComments] = useState([])
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/comments/${videoID}/`)
      .then(res => {
        setComments(res.data)
        setLoading(false)
      })
      .catch(error => {
        setMessage(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message)
      })
    },[videoID])


  return (
    <div className = "side">
      <div className="activities">
        <div className="activities__header">
          <p>COMMENTS</p>
          <img src = {close} onClick = {()=>setCommentActive(false)} alt = ""/>
        </div>
        {
          loading ? <div>Loading...</div> :
          message ? <h3>{message}</h3> :
          comments && comments.length ?
          <div className = "comments-content">
          {
            comments.map(comment =>
              <div key = {comment.id} style = {{width: "auto"}}>
                <Comment
                  comment = {comment}
                  setComments = {setComments}
                  userInfo = {userInfo}
                 />
             </div>
            )
          }
          </div>: <h4>NO COMMENTS YET</h4>
        }
        { userInfo &&
          <AddComment
            setComments = {setComments}
            videoID = {videoID}
          />
        }
      </div>
    </div>
  )
}

export default CommentSection
