import React from 'react'
import Slides from './Slides';
// import "./Slider.css";

export default function SliderContent({ displayCount, sliderContentWidth, slideWrapperWidth, translate, tvData, slideClickHandler, playlistSlideClickHandler, updateActivePlaylistId, goHomePlaylist }) {

    return (
        <div className='slider-content'
            style={{
                transform: `translateX(-${translate}vw)`,
                transition: `transform ease-in-out .5s`,
                height: '100%',
                width: `${sliderContentWidth}vw`,
                display: 'flex',
            }}
        >
            <Slides
              displayCount={displayCount}
              slideWrapperWidth={slideWrapperWidth}
              tvData={tvData}
              slideClickHandler={slideClickHandler}
              playlistSlideClickHandler={playlistSlideClickHandler}
              updateActivePlaylistId={updateActivePlaylistId}
              goHomePlaylist={goHomePlaylist} />
        </div>
    )
}
