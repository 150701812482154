import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
import Slide from '../slides/Slide'
import Modal from '../modal/Modal'
import videoUp from '../icons/videoUp.svg'
import close from '../icons/modalClose.svg'
import videoDown from '../icons/videoDown.svg'
import Delete from '../icons/videoDelete.svg'
import Add from '../icons/AddforAllVideos.svg'
import VideoInfo from '../slides/VideoInfo'
import AddVideoToPlaylistContent from '../playlistAdds/AddVideoToPlaylistContent'

const AllPlaylistVideosComponent = ({playlistID, myVideo, displayCount, slideClickHandler, setPlaylistVideos, dataHeight, deleteActive, playlists, setPlaylists, activePlaylist, playlistVideos}) => {

  const [delVideoTrigger, setDelVideoTrigger] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const adPlRef = useRef()


  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (adPlRef == null || adPlRef.current == null || !adPlRef.current.contains(event.target)){
        setAddActive(false)
      }
    })
  })



  const moveVideoUp = (video) => {
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/moveVideo/${video.id}/${playlistID}/0/`,
    {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
         }
      }
    ).then(response => {
        setPlaylistVideos(response.data)
      })
      .catch(error => {
        console.log(error.message.detail)
      })
  }

  const moveVideoDown = (video) => {
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/moveVideo/${video.id}/${playlistID}/1/`,
    {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
         }
      }
    ).then(response => {
        setPlaylistVideos(response.data)
      })
      .catch(error => {
        console.log(error.message.detail)
      })
  }

  const deleteHandler = (data) =>{
    axios.delete(`${process.env.REACT_APP_API_URL}/memoApp/deletePlaylistVideo/${playlistID}/${data.id}/`,
      {
      headers:{
        'Content-type':'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
           }
        }
      ).then(response => {
          setPlaylistVideos((prevArr) => prevArr.filter(item => item.id !== data.id))
          setDelVideoTrigger(false)
        })
        .catch(error => {
          console.log(error)
        })
  }


  return(
    <>
      <div className="single-video-bg"
          style={{ height: dataHeight + 'vw' }}
      >
          <Slide
            content={myVideo}
            displayCount={displayCount}
            slideClickHandler = {slideClickHandler}
            width = {"9vw"}
            />
      </div>
      <div className = "name-edit-delete">
        <div style = {{width: "65%"}}>
          <VideoInfo data = {myVideo}/>
        </div>
        { 
          deleteActive &&
          <div className = "icon-wrapper">
            <div onClick = {() => setDelVideoTrigger(true)}>
              <img src = {Delete} style = {{margin: "0 0.2vw"}} alt = "delete"/>
            </div>
            <div onClick={()=>{setAddActive(!addActive)}} ref = {adPlRef}>
              <img src = {Add} style = {{margin: "0 0.2vw"}} alt = {"Add"}/>
              {
              addActive && 
                <AddVideoToPlaylistContent
                  setPlaylists = {setPlaylists}
                  setIsActive = {setAddActive}
                  playlists = {playlists}
                  activeVideo = {myVideo}
                  playlistVideos = {playlistVideos}
                  setPlaylistVideos = {setPlaylistVideos}
                  activePlaylist = {activePlaylist}
                />
            }
            </div>
            <div onClick={()=>moveVideoUp(myVideo)}>
              <img src = {videoUp} style = {{margin: "0 0.2vw"}} alt = {"videoUp"}/>
            </div>
            <div onClick={()=>moveVideoDown(myVideo)}>
              <img src = {videoDown} style = {{margin: "0 0.2vw"}} alt = {"videoDown"}/>
            </div>
            <Modal
              trigger = {delVideoTrigger}
              setTrigger = {setDelVideoTrigger}
              content = {
                <div className = "delContent">
                  <div className = "modal-close">
                    <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setDelVideoTrigger(false)}/>
                  </div>
                  <h5>Are You sure You Want to delete {'<<' + myVideo.name + '>>'}</h5>
                  <button onClick = {()=>deleteHandler(myVideo)}>YES</button>
                </div>
              }
            />
          </div>
          }
        </div>
      </>
  )
}

export default AllPlaylistVideosComponent
