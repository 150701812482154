import React, {useState} from 'react'
import './AddToPlaylist.css'
import AddVideoToPlaylistList from './AddVideoToPlaylistList'
import axios from 'axios'
import whiteClose from '../icons/whiteClose.svg'

const AddVideoToPlaylistContent = ({userInfo, setPlaylists, setIsActive, playlists, activeVideo, setPlaylistVideos, activePlaylist}) => {

  const [message, setMessage] = useState()
  const [playlistName, setPlaylistName] = useState()

  const [createActive, setCreateActive] = useState(false)

  const config = {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
     }
  }

  const submitHandler = (e) => {
    e.preventDefault()
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/addPlaylist/`,
      { 'playlistName': playlistName },
      config
    )
      .then(response => {
        if(playlists && playlists.length){
          setPlaylists((prev)=>{return [response.data, ...prev]} )
        } else {
          setPlaylists([response.data])
        }

        setIsActive(false)
      })
      .catch(message => {
        setMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      })
  }

  return (
    <div className = 'addToplaylists'>
      {
        !userInfo ?
        <div style = {{fontSize:"1.5rem", fontWeight:"400"}}>Login Required</div> :
        <>
          {
            !createActive ?
              <>
                <div className = "choosePL">
                  <h6>Choose Playlist</h6>
                  <div onClick={()=>{setIsActive(false)}}>
                    <img src = {whiteClose} alt = "close" style = {{width:"1vw"}}/>
                  </div>
                </div>
                {message && <h4>{message}</h4>}
                <AddVideoToPlaylistList
                  setPlaylists = {setPlaylists}
                  playlists = {playlists}
                  activeVideo = {activeVideo}
                  setPlaylistVideos = {setPlaylistVideos}
                  activePlaylist = {activePlaylist}
                />
                <div className = "create-button">
                  <button type = "submit" onClick = {()=>setCreateActive(true)} variant = 'primary'> + Create New</button>
                </div>
              </>:
              <>
                <div className = "plName-input" >
                  <input
                    type = "text"
                    placeholder = "Name..."
                    value = {playlistName}
                    onChange = {(e) => setPlaylistName(e.target.value)}
                    required
                  />
                </div>
                <div className = "create-button">
                  <button type = "submit" onClick = {submitHandler} variant = 'primary' className = "create" style = {{width:"8vw"}}>Create</button>
                </div>
            </>

          }
        </>
      }
    </div>

  )

}

export default AddVideoToPlaylistContent
