import React from 'react'
import './Footer.css'

const Footer = () => {
  return(
    <div className = "footer">
    </div>
  )
}

export default Footer
