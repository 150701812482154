import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const RedirectHomePage = () => {

    const {uid, token} = useParams()
    const navigate = useNavigate()
    localStorage.setItem('uid', uid)
    localStorage.setItem('token', token)

    useEffect(()=>{
        navigate('/')
    },[])

    return(
        <></>
    )
}

export default RedirectHomePage