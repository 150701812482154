import React, {useState, useRef} from 'react'
import './Header.css'
import {useNavigate } from 'react-router-dom'
import login from '../icons/login.svg'
import Login from '../user/Login'
import Modal from '../modal/Modal'

const Profile = ({userInfo, setUserInfo}) => {

  const [profileMenuActive, setProfileMenuActive] = useState(false)
  const [loginModalTrigger, setLoginModalTrigger] = useState(false)
  const [content, setContent] = useState()

  const navigate = useNavigate()

  const logoutHandler = () =>{
      setUserInfo(null)
      localStorage.removeItem('access')
      localStorage.removeItem('userInfo')
      navigate('/')
    }


  return (
    <div className = "header-profile">
    {
      userInfo ? (
      <div id = {userInfo.id} className="header-username">
        <div className="header-profile" onClick = {()=>{setProfileMenuActive(!profileMenuActive)}}>
          <img src={login}  alt="" id = "login-img"/>
          <div className="profile-name" >{userInfo.first_name}</div>
        </div>
        {
          profileMenuActive &&
          <div className = "profile-menu" onMouseLeave = {()=> setProfileMenuActive(false)}>
            <div className = "profile-menu-object" onClick = { logoutHandler } >Logout</div>
            {
              userInfo.is_staff && <div className = "profile-menu-object" onClick = {()=>{navigate('/admin')}}>Admin Page</div>
            }
          </div>
        }
      </div>
      ) : (
        <div>
          <div className='header-profile'
            onClick={()=>{
              setLoginModalTrigger(true); 
              setContent(<Login 
                userInfo={userInfo}
                setUserInfo = {setUserInfo}
                loginModalTrigger = {loginModalTrigger}
                setLoginModalTrigger = {setLoginModalTrigger}
                setContent = {setContent}
              />)}}>
            <img src={login}  alt="" id = "login-img"/>
            <div className="profile-name" >
              Login
            </div>
          </div>
          <Modal
              trigger = {loginModalTrigger}
              setTrigger = {setLoginModalTrigger}
              content = {
                content
              }
            />
        </div>
      )
    }
    </div>
  )
}

export default Profile


