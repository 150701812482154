import React, { useState } from 'react'
import send from '../icons/send.svg'
import axios from 'axios'
import './CommentsSection.css'

const AddComment = ({setComments, videoID}) => {

  const [commentText, setCommentText] = useState('')
  const [message, setMessage] = useState('')


  const config = {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
     }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (commentText.length && commentText !== commentText.length*' ') {
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/addComment/`,
      { 'text': commentText, 'video_id': videoID },
      config
    )
      .then(response => {
        setMessage('')
        setComments((prev)=>{ return[response.data, ...prev ]})
        setCommentText('')
      })
      .catch(message => {
        setMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      })
    } else {
      console.log('ok')
    }

  }


  return (
    <div className = "addComment-form">
      {message && <h5>{message}</h5> }
      <div className = "addComment-content">
        <textarea className = "textarea"
          rows = {3}
          wrap = "virtual"
          type = "text"
          placeholder = "Write a comment"
          value = {commentText}
          onChange = {(e) => setCommentText(e.target.value)}
          required >
        </textarea>
        <div id = "send-image">
          <img src = {send}  style = {{width:"1.5vw"}} alt = "add" onClick = {submitHandler}/>
        </div>
      </div>
    </div>
  )
}

export default AddComment

// style = {{width:"1.5vw"}}
