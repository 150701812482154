import './App.css';
import Header from './app-main-content/header_footer/Header'
import Content from './app-main-content/Content'
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import axios from 'axios'


function App() {
  //search keyword in search component
  const [keyWord, setKeyWord] = useState('empty')
  //filter component
  const [filterId, setFilterId] = useState(0)
  //filter by mood or genre
  const [activeVideo, setActiveVideo] = useState()
  const [userInfo, setUserInfo] = useState()
  const [searchResults, setSearchResults] = useState()
  const [showAllSearchPath, setShowAllSearchPath] = useState('')

  const startSearch = (filterId, keyWord, moodGenreID) => {
    setShowAllSearchPath(`search-results/${filterId}/${moodGenreID}/${keyWord}`)
    setSearchResults([])
    if(filterId !== 3 && filterId !== 5 && keyWord.length === 0 ){
      console.log('fill the search bar')
    } else {
      setSearchResults()
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/searchResults/${0}/`,
        { 'filterId' : filterId, 'moodGenreID': moodGenreID, 'keyWord': keyWord }
      ).then(res => { 
        setSearchResults(res.data)
      }
      ).catch(error => {
        console.log(error)
        console.log(searchResults)
        setSearchResults([])

        // setMessage(error.response && error.response.data.detail
        // ? error.response.data.detail
        // : error.message)
      })
      // searchResultRef.current && searchResultRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <Router>
      <div className="App">
        <Header
          setFilterId = {setFilterId}
          setKeyWord = {setKeyWord}
          userInfo = {userInfo}
          setUserInfo = {setUserInfo}
          startSearch = {startSearch}
        />
        <ReactNotifications />
        <Content
          searchResults = {searchResults}
          keyWord = {keyWord}
          filterId = {filterId}
          userInfo = {userInfo}
          setUserInfo = {setUserInfo}
          activeVideo = {activeVideo}
          setActiveVideo = {setActiveVideo}
          showAllSearchPath = {showAllSearchPath}
        />
      </div>
    </Router>
  )
}

export default App

        //
