import React, {useState, useEffect, useRef} from 'react'
import Modal from '../modal/Modal'
import axios from 'axios'
import './RecentlyAdded.css'
import Search from '../icons/search.svg'
import SingleVideo from './SingleVideo'

const RecentlyAdded = ({videos, setVideos, genres, moods, userInfo, setActiveVideo}) => {

  const [adminSearchActive, setAdminSearchActive] = useState(false)
  const [currentAdminSearchResults, setCurrentAdminSearchResults] = useState()
  const [adminSearchValue, setAdminSearchValue] = useState('')
  const [adminSearchResults, setAdminSearchResults] = useState([])
  let adminSearchRef = useRef()
  let resultsRef = useRef()


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/recentlyAdded/`,
      {
        headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
         }
      }
    ).then(res => {
      setVideos(res.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [])



  const adminChangeHandler = (e) => {
    e.preventDefault()
    if(!adminSearchActive){
      setAdminSearchActive(true)
    }
    setAdminSearchValue(e.target.value)
    if(e.target.value.length >= 2){
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/currentSearch/${e.target.value}/${4}/`,
      ).then(res => {
        setCurrentAdminSearchResults(res.data)
      })
      .catch(error =>
        console.log(error)
    )
    }
}

const adminChooseHandler = (value) => {
    setAdminSearchValue(value.name)
    setAdminSearchActive(false)
    setCurrentAdminSearchResults([])
}


const typeHandler = (e) => {
  if(e.key === "Enter"){
    search()
  }
}

const search = () => {
  axios.post(`${process.env.REACT_APP_API_URL}/memoApp/adminSearchResults/${0}/`,
  { 'limit': 10, 'keyWord': adminSearchValue, 'filterId' : 0 }
  ).then(res => {
    setAdminSearchResults(res.data)
    setAdminSearchActive(false)
    setCurrentAdminSearchResults([])
    resultsRef.current && resultsRef.current.scrollIntoView({ behavior: "smooth" })
  })
  .catch(error =>
    console.log(error)
  )
}

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (adminSearchRef == null || adminSearchRef.current == null || !adminSearchRef.current.contains(event.target)){
        setAdminSearchActive(false)
      }
    })
  })

  return (
    <div className = "content-table">
      <div className = "recently-header">
       <h4>RECENTLY ADDED</h4>
       <div className = "admin-search">
        <input
          type="text"
          placeholder="Search"
          value = {adminSearchValue}
          onChange = {adminChangeHandler}
          onKeyPress = {typeHandler}
        />
        <img src = {Search} onClick = {search}/>
       </div>
      </div>
      {
      adminSearchActive && adminSearchValue && adminSearchValue.length && currentAdminSearchResults && currentAdminSearchResults.length ?
        <div className = "addVideoDropdown" style = {{marginLeft:"30vw", marginTop:"0.3vw"}} ref = {adminSearchRef}>
          <div className = "addVideoDropdown-content">
            {
              currentAdminSearchResults.map((result) => (
                  <div
                    className = "addVideoDropdown-item"
                    key = {result.id}
                    onClick = {()=>adminChooseHandler(result)}
                  >{result.name}</div>
              ))
            }
          </div>
        </div>: ''
    }
      {
        videos && videos.length ?
          <div className = "table-inner">
            {
              videos.map(video =>
                <div className = "single-video" key = {video.id}>
                  <SingleVideo
                    video = {video}
                    userInfo = {userInfo}
                    genres = {genres}
                    moods = {moods}
                    setVideos = {setVideos}
                    setAdminSearchResults = { adminSearchResults }
                    setActiveVideo = {setActiveVideo}
                  />
                </div>
              )
            }
          </div>:''
        }
        {
          adminSearchResults && adminSearchResults.length ?
            <div className = "table-inner" ref = {resultsRef}>
              <div className = "recently-header">
                <h4>SEARCH RESULTS:</h4>   
              </div>
              {
                adminSearchResults.map(video =>
                    <div className = "single-video" key = {video.id}>
                      <SingleVideo
                        video = {video}
                        userInfo = {userInfo}
                        genres = {genres}
                        moods = {moods}
                        setVideos = {setVideos}
                        setAdminSearchResults = { adminSearchResults }
                        setActiveVideo = {setActiveVideo}
                      />
                    </div>
                )
              }
            </div>:''
        }
    </div>
  )
}

export default RecentlyAdded
