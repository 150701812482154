import React, {useState, useEffect} from 'react'
import './CuAnalyze.css'
import AnalyzeResults from './AnalyzeResults'
import axios from 'axios'


const CuAnalyze = ({currentTime, setAnalyzeActive, activeVideo, analyzed}) => {

    const [activeStatus, setActiveStatus] = useState(1)
    const [textAnalyze, setTextAnalyze] = useState({})
    const [wtAnalyze, setWtAnalyze] = useState({})
    const [ftAnalyze, setFtAnalyze] = useState({})
    const [activeAnData, setActiveAnData] = useState({})

    const params = new URLSearchParams();
    params.append('a', 5);
    params.append('url', activeVideo.link);
    params.append('sp', currentTime);
    params.append('vl', activeVideo.duration);
    params.append('wm', -1);
    params.append('fp', -1);
    params.append('sim', -1);
    params.append('text', 1);


    const params1 = new URLSearchParams();
    params1.append('a', 5);
    params1.append('url', activeVideo.link);
    params1.append('sp', currentTime);
    params1.append('vl', activeVideo.duration);
    params1.append('wm', 1);
    params1.append('fp', -1);
    params1.append('sim', -1);
    params1.append('text', -1);


    const params2 = new URLSearchParams();
    params2.append('a', 5);
    params2.append('url', activeVideo.link);
    params2.append('sp', currentTime);
    params2.append('vl', activeVideo.duration);
    params2.append('wm', -1);
    params2.append('fp', 1);
    params2.append('sim', -1);
    params2.append('text', -1);


  useEffect(()=>{
        setTextAnalyze({})
        setActiveAnData({})
        axios.post("https://www.cumarket.net/ajax.php",
        params
        ).then(res => {
                setTextAnalyze(res.data.data.result.text_track)
                setActiveAnData(res.data.data.result.text_track)
                setActiveStatus(1)
                // setAnalyzeActive(false)
            }
        ).catch(error => 
            {
                console.log(error)  
                // setAnalyzeActive(false)
            }
        )
  },[currentTime])


  useEffect(()=>{
        setWtAnalyze({})
        setAnalyzeActive(true)
        axios.post("https://www.cumarket.net/ajax.php",
        params1
        ).then(res => {
            setWtAnalyze(res.data.data.result.wm_track)
            // setAnalyzeActive(false)
            }
        ).catch(error => {
            console.log(error)  
            // setAnalyzeActive(false)
        }
        )
  },[currentTime])


  useEffect(()=>{
        setFtAnalyze({})
        setAnalyzeActive(true)
        axios.post("https://www.cumarket.net/ajax.php",
        params2
        ).then(res => {
            setFtAnalyze(res.data.data.result.fp_track)
            setAnalyzeActive(false)
          }
        ).catch(error => 
            {
              console.log(error) 
              setAnalyzeActive(false)
            } 
        )
  },[currentTime])


    return(
        activeAnData && activeAnData.track_id ?
        <div>
            <div className = "analyzeHeader">
                <h3>ANALYSIS RESULTS</h3>
                <div className = "analyzeVarieties">
                    {
                        // textAnalyze && Object.keys(textAnalyze).length ?
                        textAnalyze && textAnalyze.track_id ?
                        <div className = {activeStatus === 1 ? "anVar active" : "anVar"}  onClick={()=>{setActiveAnData(textAnalyze); setActiveStatus(1)}}>Text</div> : ''
                    }
                    {
                        wtAnalyze && wtAnalyze.track_id ?
                        <div className = {activeStatus === 2 ? "anVar active" : "anVar"}  onClick={()=>{setActiveAnData(wtAnalyze); setActiveStatus(2)}}>Watermark</div> : ''
                    }
                    {
                        ftAnalyze && ftAnalyze.track_id ?
                        <div className ={activeStatus === 3 ? "anVar active" : "anVar"}  onClick={()=>{setActiveAnData(ftAnalyze); setActiveStatus(3)}}>Footprint</div> : ''
                    } 
                </div>
            </div>
            <AnalyzeResults 
                data = {activeAnData}
            />
        </div> : ''
    )
}

export default CuAnalyze