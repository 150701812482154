import React, { useEffect, useState } from 'react';
import './Genres.css'
import axios from 'axios'

function Genres({slideClickHandler, setFilteredLatestVideos, latestVideos}) {

  const [genres, setGenres] = useState([])
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState()
  const [activeGenres, setActiveGenres] = useState([])


  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/genres/`)
      .then(res => {
        setGenres(res.data)
        if(!localStorage.getItem('activeGenres')){
          const genreIds = res.data.map(genre => genre.id)
          localStorage.setItem('activeGenres', JSON.stringify(genreIds))
        }
        let gens = JSON.parse(localStorage.getItem('activeGenres'))
        setActiveGenres(gens)
        setLoading(false)
      })
      .catch(error => {
        setMessage(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message)
      })
  },[])


  const clickHandler = (e) => {
    e.preventDefault()
    let actGen = activeGenres
    if(e.target.className === 'genre active')
        {
          e.target.className = 'genre'
          actGen = actGen.filter(item => item !== parseInt(e.target.id))
          setActiveGenres((prevArr) => prevArr.filter(item => item !== parseInt(e.target.id)))
          setFilteredLatestVideos((prevArr) => (prevArr.filter(video => video.genre !== parseInt(e.target.id))).sort(() => .5 - Math.random()))
        }
    else
        {
          e.target.className = 'genre active'
          actGen.push(parseInt(e.target.id))
          setActiveGenres((prevArr)=>{ return[parseInt(e.target.id), ...prevArr] })
          const videos = latestVideos.filter(video => video.genre === parseInt(e.target.id))
          setFilteredLatestVideos((prevArr) => { return [...videos, ...prevArr].sort( () => .5 - Math.random()) })
        }
    setActiveGenres(actGen)
    localStorage.setItem('activeGenres', JSON.stringify(actGen))
  }




  return (
    <div>
      <div className="genres">
        {
          loading ? <div>Loading...</div> :
          message ? <h3>{message}</h3> :
          genres && genres.length ?
          genres.map((genre)=>(
              <div
                key = {genre.id}
                id = {genre.id}
                name = {genre.name}
                className={activeGenres && activeGenres.length && activeGenres.includes(genre.id) ? 'genre active' : 'genre'}
                onClick={clickHandler}
              >
              {genre.name}
              </div>
            )
          ) : ''
        }
      </div>
    </div>
  )
}

export default Genres;
