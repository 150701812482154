import React, {useState} from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import axios from 'axios'
import Register from './Register'
import ResetPassword from './ResetPassword'
import close from '../icons/modalClose.svg'

import './LoginRegister.css'

const Login = ({userInfo, setUserInfo, setContent, setLoginModalTrigger}) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState()
 
  const navigate = useNavigate()

  const config = {
    headers:{
      'Content-type':'application/json',
      'Accept': 'application/json'
     }
  }

  const continueWithGoogle = (e) => {
    e.preventDefault()
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}`,
      config
    ).then(res =>
      {
        window.location.replace(res.data.authorization_url)
      }
    ).catch(error => {
      console.log(error)
    }
    )
  }


  const submitHandler = (e) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_API_URL}/memoApp/auth/jwt/create/`,
      { 'email': email, 'password': password},
      {
        headers:{
          'Content-type':'application/json',
         }
      }
    )
      .then(response => {
        localStorage.setItem('access', response.data['access'])
        if (response.data['access']){
          const config = {
              headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
               }
            }

            axios.get(`${process.env.REACT_APP_API_URL}/memoApp/auth/users/me/`,
              config
            )
              .then(response => {
                setUserInfo(response.data)
                navigate('/')
              })
              .catch(message => {
                setMessage("Something went wrong, please, try again")
              })
        }
      })
      .catch(message => {
        setMessage("Email Or Password Is Not Valid")
      })
  }


  if (userInfo && userInfo.length){
      return <Navigate to = '/'/>
    } else {
    return (
      <div className = "login-form">
          <div className = "modal-close">
            <img src = {close} alt = "close" style = {{width:"0.68vw", height:"0.68vw"}} onClick = {()=>setLoginModalTrigger(false)}/>
          </div>
          {message && <div className = "message">{message}</div>}
          <div className = "login-input-wrapper">
            <input className = "login-Input" type="email" value = {email} placeholder="Email" onChange = {(e) => setEmail(e.target.value)} />
          </div>

          <div className = "login-input-wrapper">
            <input className = "login-Input" type="password" value = {password} placeholder="Password" onChange = {(e) => setPassword(e.target.value)} />
          </div>
          
          <div className = 'continue-google' onClick = {continueWithGoogle}>Continue with Google</div>

          <button className = "login-button" onClick={submitHandler}>Login</button>

          <div className = "move-to-register">Don't have an account? 
            <span onClick={()=>{setContent( <Register
              userInfo={userInfo}
              setUserInfo = {setUserInfo}
              setContent = {setContent}
              setLoginModalTrigger = {setLoginModalTrigger}
            />)} }> Register</span>
          </div>

          <div className = "move-to-register"><span onClick={()=>setContent(
            <ResetPassword
              userInfo={userInfo}
              setLoginModalTrigger = {setLoginModalTrigger}
            />
          )}>Forgot Your Password?</span></div>
    </div>
    )
  }
}

export default Login
