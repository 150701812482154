import React from 'react'

export default function Slide({ content, displayCount, slideClickHandler, tvData, width }) {
    const borderThickness = displayCount === 4 ? 15 : 1;
    const imgHeight = displayCount === 4 ? 8.1 : 5.3;

    return (
        <img  src={content.thumbnail} alt = ""
            onClick={() => slideClickHandler(content, tvData)}
            style={
                {
                    objectFit: 'cover',
                    border: `.${borderThickness}vw solid #cc33ff`,
                    width: `${width}`,
                    height: `${imgHeight}vw`,
                    cursor: 'pointer'
                }
            }>
        </img>
    )
}
