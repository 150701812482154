 import React, {useState, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom'
import '../AllVideos.css'
import axios from 'axios'
import Slide from '../slides/Slide'
import Footer from '../header_footer/Footer'
import VideoInfo from '../slides/VideoInfo'


const AllOtherPlaylists = ({setActivePlaylist, displayCount})=> {

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const [allOtherPlaylists, setAllOtherPlaylists] = useState()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const handleScroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if(scrollHeight - scrollTop === clientHeight){
      setPage(prev => prev + 1)
    }
  }


  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/otherPlaylists/${page}/`)
      .then(res => {
        if (res.data && res.data.length){
          if (page > 1){
            setAllOtherPlaylists(prev => [...prev, ...res.data])
          } else {
            setAllOtherPlaylists(res.data)
          }
        }
        setLoading(false)
      })
      .catch(error => {
        setMessage(error.response && error.response.data.message
        ? error.response.data.message
        : error.message)
      })
  },[])

const playlistClickHandler = (data) => {
  setActivePlaylist(data)
  navigate(`/playlist/${data.id}/${data.name}`)
}


  return (
    <div className = "allVideos-wrapper">
      <div className = "all-playlist-videos">
        <div className = "show-all-heading">
          <h6>All Playlists</h6>
          <div className = "sah-icons-wrapper">
          </div>
        </div>
        <div className = "all-playlist-videos-content" onScroll = {handleScroll}>
        {
          loading ? <div>Loading...</div> :
          message ? <h3>{message}</h3> :
          allOtherPlaylists && allOtherPlaylists.length ?
            allOtherPlaylists.map(playlist =>(
              <div key = {playlist.id} className = "single-video-wrapper">
                <div className = "single-video-bg">
                  <Slide
                    content = {playlist}
                    displaycount = {displayCount}
                    slideClickHandler={playlistClickHandler}
                    width = {"9vw"}
                  />
                </div>
                <VideoInfo data = {playlist}/>
              </div>
              ) 
            )
            : ''
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default AllOtherPlaylists
