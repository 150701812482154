import React from 'react'
import './Header.css'

const GenreMoodContent = ({data, filterID, startSearch, setFilterBy, setActiveFilter, setListActive }) => {


  const filterByGenreMood = (item) => {
    setFilterBy(item.name)
    startSearch(filterID, '', item.id)
    setActiveFilter(false)
  }


  return (
    <div className = "genre-list" onMouseLeave = {()=>setListActive(false)}>
      {
        data.map((item)=>(
          <div
            className = "filter-by-genre"
            id = {item.id}
            key = {item.id}
            name  = {item.name}
            onClick = {() => filterByGenreMood(item)}>
            {item.name}
          </div>
        ))
      }
    </div>
  )
}

export default GenreMoodContent
