import React, {useState, useEffect, useRef} from 'react'
import './Header.css'
import genreArrow from '../icons/genreArrow.svg'
import axios from 'axios'
import GenreMoodContent from './GenreMoodContent'

const FilterContent = ({setSearchId, setFilterBy, startSearch, setActiveFilter}) => {

  // in filter menu activates genres list
  const [genreListActive, setGenreListActive] = useState(false)
  // in filter menu activates Moods list
  const [moodListActive, setMoodListActive] = useState(false)
  const [genres, setGenres] = useState([])
  const [moods, setMoods] = useState([])


  // chooses filter component, with which searches results e.g title
    const filterChoice = (e) => {
      e.preventDefault()
      setSearchId(parseInt(e.target.id))
      setFilterBy(e.target.getAttribute('name'))
    }



    const mouseGenreEnter = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/genres/`)
        .then(res => {
          setGenres(res.data)
          setGenreListActive(true)
        })
        .catch(error => {
          console.log('could not fetch')
        })
    }


    const mouseMoodEnter = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/moods/`)
        .then(res => {
          setMoods(res.data)
          setMoodListActive(true)
        })
        .catch(error => {
          console.log('could not fetch')
        })
    }

//

  return (
    <div className = "filter-list" onMouseLeave = {()=>setActiveFilter(false)}>
      <div className = "filter-by" id = {0} name = "All" onClick = {filterChoice}>All</div>
      <div className = "filter-by" id = {1} name = "Artist" onClick = {filterChoice}>Artist</div>
      <div className = "filter-by" id = {2} name = "Composer" onClick = {filterChoice}>Composer</div>
      <div className = "filter-genre" id = {3} name = "Genre" onClick = {mouseGenreEnter}>
        <div>Genre</div>
        <img src = {genreArrow} alt = "" />
        { genreListActive && genres && genres.length &&
          <GenreMoodContent
            startSearch = {startSearch}
            data = {genres}
            filterID = {3}
            setFilterBy = {setFilterBy}
            setActiveFilter = {setActiveFilter}
            setListActive = {setGenreListActive}
          />
        }
      </div>
      <div className = "filter-genre" id = {5} name = "Mood" onClick = {mouseMoodEnter}>
        <div>Mood</div>
        <img src = {genreArrow} alt = "" />
        { moodListActive && moods && moods.length &&
          <GenreMoodContent
            startSearch = {startSearch}
            data = {moods}
            filterID = {5}
            setFilterBy = {setFilterBy}
            setActiveFilter = {setActiveFilter}
            setListActive = {setMoodListActive}
          />
        }
      </div>
      <div className = "filter-by" id = {4} name = "Title" onClick = {filterChoice}>Title</div>
    </div>
  )
}

export default FilterContent
