import React, { useState, useEffect} from 'react';
import AllMyPlaylists from './playlists/AllMyPlaylists';
import AllMyPlaylistVideos from './playlist/AllMyPlaylistVideos';
import AllOtherPlaylistVideos from './playlist/AllOtherPlaylistVideos';
import AllSearchResults from './searchResults/AllSearchResults'
import AllLatestVideos from './latestVideos/AllLatestVideos';
import AllRelatedVideos from './relatedVideos/AllRelatedVideos';
import AllOtherPlaylists from './playlists/AllOtherPlaylists';
import AllWeeksPopular from './popular/AllWeeksPopular'
import AllMonthPopular from './popular/AllMonthPopular'
import RedirectHomePage from './user/RedirectHomePage'
import AdminPage from './admin/AdminPage'
import {Routes, Route, Navigate} from 'react-router';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom'
import Home from './Home';
import axios from 'axios'
import queryString from 'query-string';

function Content({ userInfo, setUserInfo, searchResults, activeVideo, setActiveVideo, showAllSearchPath }) {

  const [playlistVideos, setPlaylistVideos] = useState()
  const [activePlaylist, setActivePlaylist] = useState()
  const [activeData, setActiveData] = useState([])
  const [playing, setPlaying] = useState(false)
  const [analyzed, setAnalyzed] = useState(false)
  const [analyzeActive, setAnalyzeActive] = useState(false)
  const navigate = useNavigate()


  let location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams()

  let videoId= parseInt(searchParams.get('videoId')) || 0


  useEffect(()=>{
    axios.get('https://te.cugate.com/whoami.php').then(
      res => console.log(res)
    ).catch(
      error => console.log(error))
  },[])


  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/getVideo/${videoId}/`
    ).then(res => 
      {
        setActiveVideo(res.data)
      }
    ).catch(error =>{
      setActiveVideo({id: 63, name: 'BEAUTIFUL WORLD MUSIC SET', added: '2022-02-05T14:23:07.788721Z', duration: 4993, members: ['some cool dude'], link: 'https://www.youtube.com/watch?v=tp_y1B1eQeo', thumbnail: 'https://i.pinimg.com/originals/37/96/ac/3796ac68b8fc1e4656c1f8beb0d0424c.jpg', genre:'10', members:['masala']})
    }
   )
  },[])



///////////////////////////////////////authentication block below /////////////////////////////

  
  //loads user according to access token kept in localstorage
  const load_user = () => {
    if (localStorage.getItem('access')){
        axios.get(`${process.env.REACT_APP_API_URL}/memoApp/auth/users/me/`,
          {
            headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
            }
          }
        )
          .then(response => {
            setUserInfo(response.data)
          })
          .catch(error => {
            console.log(error)
          })
      }
  }


  //In case of google authentification, authenticates user, keeps access token in localstorage and loads user info accordingly
  const googleAuthenticate = (state, code) => {
    if (state && code && !localStorage.getItem('access')){
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

      const details = {
        'state': state,
        'code': code
      };

      const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/auth/o/google-oauth2/?${formBody}`,
        config
      ).then(res =>
        {
          localStorage.setItem('access', res.data.access)
          load_user()
        }
      ).catch(error =>
        {
          console.log('authenticated with error')
          console.log(error)
        }
      )
    } else {
      load_user()
    }
  }


  //checks if user is authenticated by google or from program and works accordingly
  useEffect(()=>{
    const values = queryString.parse(location.search);
    const state = values.state ? values.state : null;
    const code = values.code ? values.code : null;

    if(state && code){
      googleAuthenticate(state, code)
    } else {
      load_user()
    }

  },[])



///////////////////////////////////////authentication block above /////////////////////////////


  //with clicking each slide(video), changes active video and active slider
  //(e.g if video is from latestVideos, active slider will be latest videos)
  const slideClickHandler = (data, activeVideos) => {
    setAnalyzeActive(false)
    setAnalyzeActive(false)
    setAnalyzed(false)
    setActiveVideo(data)
    setActiveData(activeVideos)
    setPlaying(true)
    videoId = data.id
    setSearchParams({videoId})
    axios.put(`${process.env.REACT_APP_API_URL}/memoApp/count/${data.id}/`
      ).then(res => {
        console.log('click Added')
      })
      .catch(message => {
        console.log(message)
      })

    if (window.location.pathname !== '/'){
      navigate(`/?videoId=${data.id}`)
    }
  }

  const showAllClickHandler = (chosenShowAllPath) => {
    navigate(`/${chosenShowAllPath}`)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const goBack = () => {
    navigate(-1)
  }

  const goHome = () => {
    navigate('/')
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const goHomePlaylist = (playlistId) => {
    Navigate(`/?playlist=${playlistId}`)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Routes>
        <Route exact path={`/`} element = {
          activeVideo &&
          <Home
            playlistVideos = {playlistVideos}
            setPlaylistVideos = {setPlaylistVideos}
            userInfo = {userInfo}
            activePlaylist = {activePlaylist}
            setActivePlaylist = {setActivePlaylist}
            goHome={goHome}
            playing = {playing}
            setPlaying = {setPlaying}
            goHomePlaylist={goHomePlaylist}
            activeData = {activeData}
            setActiveData = {setActiveData}
            activeVideo={activeVideo}
            setActiveVideo = {setActiveVideo}
            showAllClickHandler={showAllClickHandler}
            slideClickHandler={slideClickHandler}
            analyzed = {analyzed}
            setAnalyzed = {setAnalyzed}
            analyzeActive = {analyzeActive}
            setAnalyzeActive = {setAnalyzeActive}
            searchResults = {searchResults}
            showAllSearchPath = {showAllSearchPath}
            setSearchParams = {setSearchParams}/>
        }/>
        <Route path = 'password/reset/confirm/:uid/:token' element = {<RedirectHomePage/>}/>
        <Route path = 'auth/activate/:uid/:token' element = {<RedirectHomePage/>}/>
        <Route path = '/admin' element = {<AdminPage
          userInfo = {userInfo}
          setActiveVideo = {setActiveVideo}
          />}/>
        <Route exact path='/playlists/:userID' element = {
          <AllMyPlaylists
            userInfo = {userInfo}
            showAllClickHandler={showAllClickHandler}
            setActivePlaylist = {setActivePlaylist}
            activePlaylist = {activePlaylist}
            displayCount={6}
            dataHeight={8.2}
            goHome={goHome}
            goBack={goBack}
            goHomePlaylist={goHomePlaylist}
            />
        }/>
        <Route exact path='/all/playlists' element = {
          <AllOtherPlaylists
            showAllClickHandler={showAllClickHandler}
            setActivePlaylist = {setActivePlaylist}
            activePlaylist = {activePlaylist}
            displayCount={6}
            dataHeight={8.2}
            goHome={goHome}
            goBack={goBack}
            goHomePlaylist={goHomePlaylist}
            />
        }/>
        <Route path='/latest-video' element = {
          <AllLatestVideos
            displayCount={6}
            dataHeight={5.4}
            slideClickHandler={slideClickHandler}
            goBack={goBack} />
        }/>
      <Route path='/popular-video' element = {
        <AllWeeksPopular
          displayCount={6}
          dataHeight={5.4}
          slideClickHandler={slideClickHandler}
          goBack={goBack} />
      }/>
      <Route path='/month-popular-video' element = {
        <AllMonthPopular
          displayCount={6}
          dataHeight={5.4}
          slideClickHandler={slideClickHandler}
          goBack={goBack} />
      }/>
        <Route path='/related-video/:videoID' element = {
          <AllRelatedVideos
            currentVideo={activeVideo}
            displayCount={6}
            dataHeight={5.4}
            slideClickHandler={slideClickHandler}
            goBack={goBack} />
        }/>
        <Route path='/myplaylist/:playlistID/:playlistName' element = {
          <AllMyPlaylistVideos
            userInfo = {userInfo}
            displayCount={6}
            dataHeight={5.4}
            slideClickHandler={slideClickHandler}
            goBack={goBack}
            setActivePlaylist = {setActivePlaylist}
            setPlaylistVideos = {setPlaylistVideos}
            playlistVideos = {playlistVideos}
            activePlaylist = {activePlaylist}
            />
        }/>
        <Route path='/playlist/:playlistID/:playlistName' element = {
          <AllOtherPlaylistVideos
            userInfo = {userInfo}
            displayCount={6}
            dataHeight={5.4}
            slideClickHandler={slideClickHandler}
            goBack={goBack}
            setActivePlaylist = {setActivePlaylist}
            setPlaylistVideos = {setPlaylistVideos}
            playlistVideos = {playlistVideos}
            activePlaylist = {activePlaylist}
            />
        }/>
        <Route
          path = '/search-results/:filterId/:moodGenreID/:keyWord' element = {
            <AllSearchResults
              slideClickHandler = {slideClickHandler}
              goBack={goBack}
              displayCount={6}
              dataHeight={5.4}
            />
          }
        />
        <Route path="*"  element = {
          <Navigate to="/" />
        }/>
      </Routes>
    </>
  )
}

export default Content;


// <Route path = 'password/reset/confirm/:uid/:token' element = {<ResetPasswordConfirm/>}/>
{/* <Route path = 'auth/activate/:uid/:token' element = {<UserActivate/>}/> */}