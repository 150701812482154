import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import '../AllVideos.css'
import axios from 'axios'
import goBackIcon from '../icons/PlBack.svg'
import Footer from '../header_footer/Footer'
import Slide from '../slides/Slide'

const AllOtherPlaylistVideos = ({slideClickHandler, playlistVideos, setPlaylistVideos, displayCount, dataHeight, goBack})=> {

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const [deleteActive, setDeleteActive] = useState(false)
  const { playlistID, playlistName } = useParams()


  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/users/playlistVideos/${playlistID}/`
    )
      .then(res => {
        if (res.data){
          setPlaylistVideos(res.data)
        }
        setLoading(false)
      })
      .catch(error => {
        setMessage(error.response && error.response.data.message
        ? error.response.data.message
        : error.message)
      })
    },[playlistID])

  const editHandler = (e) => {
    e.preventDefault()
    setDeleteActive(!deleteActive)
  }


  return (
      <div className = "allVideos-wrapper">
        {
          loading ? <div>Loading...</div> :
          message ? <h3>{message}</h3> :
          playlistVideos && playlistVideos.length ?
            <div className="all-playlist-videos">
                <div className="show-all-heading">
                    <h6>{playlistName}</h6>
                    <div className='sah-icons-wrapper'>
                      <div className = "header-icon-wrapper" >
                        <div onClick = {goBack}>
                          <img src = {goBackIcon} alt = "back"/>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="all-playlist-videos-content">
                    {
                        playlistVideos.map(
                            (myVideo, i) =>
                                <div
                                    className={'single-video-wrapper'}
                                    key={i}
                                >
                                <div className="single-video-bg"
                                    style={{ height: dataHeight + 'vw' }}
                                >
                                    <Slide
                                        content={myVideo}
                                        displayCount={displayCount}
                                        slideClickHandler = {slideClickHandler}
                                        width = {"9vw"}
                                        />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            : ''
        }
        <Footer/>
      </div>
  )
}

export default AllOtherPlaylistVideos
