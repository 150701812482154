import React, {useState, useEffect, useRef} from 'react'
import './SearchResult.css'
import Slider from '../slides/Slider'
import axios from 'axios'

const SearchResult = ({slideClickHandler, searchResults, showAllPath, sCWHeight, displayCount, showAllClickHandler}) => {

  const searchResultRef = useRef(null)


  useEffect(() => {
        searchResultRef.current && searchResults && searchResultRef.current.scrollIntoView({ behavior: "smooth" })
    }, [searchResults])


  let slideWrapperWidth = 9.5
  let slidePlusMargin = slideWrapperWidth + .6

  return (
    <>
      {
          searchResults ? searchResults.length ?
            <div ref={searchResultRef} className="playlists">
              <Slider
                title = {"SEARCH RESULTS"}
                displayCount={displayCount}
                sliderContentWidth={(slideWrapperWidth + .6) * searchResults.length - .6}
                slideWrapperWidth={slideWrapperWidth}
                step={slidePlusMargin}
                sCWHeight={sCWHeight}
                tvData={searchResults}
                slideClickHandler={slideClickHandler}
                showAllPath={showAllPath}
                showAllClickHandler={showAllClickHandler}
              />
            </div> :
              <div ref={searchResultRef}>
                <h3 style = {{
                  color: "#cc33ff",
                  fontFamily: "'Axiforma', sans-serif",
                  fontSize: "1.875rem",
                  fontWeight: "500",
                }}>Search Results</h3>
                <h3 style={{
                  fontFamily: "'Axiforma', sans-serif",
                  fontSize: "1.5rem",
                  fontWeight: "300"
                }}>No Videos Found</h3>
              </div>
            : ''
        }
    </>
  )
}

export default SearchResult
