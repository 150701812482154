import React, {useState, useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import '../AllVideos.css'
import axios from 'axios'
import AllPlaylistComponent from './AllPlaylistComponent'
import AddPlaylist from '../playlistAdds/AddPlaylist'
import Edit from '../icons/Edit.svg'
import Add from '../icons/AddforAllVideos.svg'
import back from '../icons/PlBack.svg'
import Footer from '../header_footer/Footer'

const AllMyPlaylists = ({ setActivePlaylist, goHome, goBack, activePlaylist, displayCount, userInfo})=> {

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [allPlaylists, setAllPlaylists] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [editPlActive, setEditPlActive] = useState(false)
  const addPlRef = useRef()
  const {userID} = useParams()

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/users/myPlaylists/${userID}/`,
      {
       headers:{
         'Content-type':'application/json',
        }
     }
    )
      .then(res => {
        if (res.data && res.data.length){
          setAllPlaylists(res.data)
        } else {
          setMessage('No Playlists Yet')
        }
        setLoading(false)
      })
      .catch(error => {
        setMessage(error.response && error.response.data.message
        ? error.response.data.message
        : error.message)
      })
  },[userInfo])

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (addPlRef == null || addPlRef.current == null || !addPlRef.current.contains(event.target)){
        setIsActive(false)
      }
    })
  })


  return (
    <div className = "allVideos-wrapper">
      <div className = "all-playlist-videos">
        <div className = "show-all-heading">
          <h6>MY PLAYLISTS</h6>
          <div className = "sah-icons-wrapper">
            { userInfo && allPlaylists && allPlaylists.length && userInfo.id === allPlaylists[0].user ?
              <div ref = {addPlRef}>
                <div className = "header-icon-wrapper" >
                  <div onClick = {goBack}>
                    <img src = {back} alt = "back"/>
                  </div>
                  <div onClick = {()=>{setEditPlActive(!editPlActive)}}>
                    <img src = {Edit} alt = "edit"/>
                  </div>
                  <div onClick = {()=>{setIsActive(!isActive)}}>
                    <img src = {Add} alt = "add"/>
                  </div>
                </div>
                {
                  isActive &&
                  <AddPlaylist
                    setPlaylists = {setAllPlaylists}
                    setIsActive = {setIsActive}
                    userInfo = {userInfo}
                    playlists = {allPlaylists}
                  />
                }
              </div>:""
            }
          </div>
        </div>
        <div className = "all-playlist-videos-content" >
        {
          loading ? <div>Loading...</div> :
          message ? <h3>{message}</h3> :
          allPlaylists && allPlaylists.length ?
            allPlaylists.map(playlist =>(
              <div key = {playlist.id} className = "single-video-wrapper">
                <AllPlaylistComponent
                  userInfo = {userInfo}
                  goHome = {goHome}
                  playlist = {playlist}
                  setActivePlaylist = {setActivePlaylist}
                  setAllPlaylists = {setAllPlaylists}
                  activePlaylist = {activePlaylist}
                  editPlActive = {editPlActive}
                  displayCount = {displayCount}
                />
              </div>
              )
            )
            : 'No Playlists'
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default AllMyPlaylists
