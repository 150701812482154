import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import './Playlists.css'
import Slider from '../slides/Slider'


const Playlists = ({userInfo, playlists, setAnalyzeActive, setPlaylists, displayCount, setAnalyzed, setSearchParams, setActiveData, setPlaying, setPlaylistVideos, setActiveVideo, activePlaylist, slideClickHandler, setActivePlaylist, goHomePlaylist, showAllPath, showAllClickHandler, sCWHeight}) => {

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const addPlRef = useRef()


  const config = {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
     }
  }

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/users/myPlaylists/${userInfo.id}/`,
      config
    )
      .then(res => {
        if (res.data && res.data.length){
          setPlaylists(()=>{ return res.data.sort( () => .5 - Math.random()) })
        }
        setLoading(false)
      })
      .catch(message => {
        setMessage(message.response && message.response.data.message
        ? message.response.data.message
        : message.message)
      })
  },[userInfo])

const playlistClickHandler = (data) => {
  setAnalyzed(false)
  setAnalyzeActive(false)
  axios.get(`${process.env.REACT_APP_API_URL}/memoApp/users/playlistVideos/${data.id}/`)
    .then(res => {
      if (res.data){
        setPlaylistVideos(res.data)
        setActiveData(res.data)
        if(res.data.length){
          setActiveVideo(res.data[0])
          const videoId = res.data[0].id
          setSearchParams({videoId})
          setPlaying(true)
        }
        setLoading(false)
      }
    })
    .catch(message => {
      setMessage(message.response && message.response.data.message
      ? message.response.data.message
      : message.message)
    })
    setActivePlaylist(data)
}


  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (addPlRef == null || addPlRef.current == null || !addPlRef.current.contains(event.target)){
        setIsActive(false)
      }
    })
  })

  let slideWrapperWidth = 9.5
  let slidePlusMargin = slideWrapperWidth + .6

  return (
    <div>
      {
        loading ? <div>Loading...</div> :
        message ? <h3>{message}</h3> :
        playlists && playlists.length ?
        <div className = 'playlists'>
          <Slider
            title = "MY PLAYLISTS"
            displayCount={displayCount}
            sliderContentWidth={(slideWrapperWidth + .6) * playlists.length - .6}
            slideWrapperWidth={slideWrapperWidth}
            step={slidePlusMargin}
            sCWHeight={sCWHeight}
            tvData={playlists}
            goHomePlaylist={goHomePlaylist}
            showAllPath={showAllPath}
            showAllClickHandler={showAllClickHandler}
            slideClickHandler = {playlistClickHandler}
          />
        </div>
       :<h3 id = "no-playlists">You Have Not Added Playlists Yet</h3>
      }
    </div>
  )
}

export default Playlists

