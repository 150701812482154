import React, {useState, useEffect, useRef} from 'react'
import './CommentsSection.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import person from '../icons/commentGuy.svg'
import Edit from '../icons/Edit.svg'
import Delete from '../icons/videoDelete.svg'


const Comment = ({setComments, comment, userInfo}) => {

  const editRef = useRef()
  const menuRef = useRef()
  const [commentEditAvtive, setCommentEditActive] = useState(false)
  const [text, setText] = useState(comment.body)
  const [message, setMessage] = useState('')
  let navigate = useNavigate()

  const deleteHandler = (data) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/memoApp/deleteComment/${data.id}/`,
        {
          headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
           }
        }
      ).then(response => {
          setComments((prevArr) => prevArr.filter(item => item.id !== data.id))
        })
        .catch(error => {
          setMessage(error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message)
        })
  }

  const editHandler = (data) => {
    if (text.trim() === comment.body){
      setCommentEditActive(false)
    }
    else if (text.length && text !== text.length*' ') {
      axios.put(`${process.env.REACT_APP_API_URL}/memoApp/editComment/${data.id}/`,
          {'text': text.trim() },
          {
            headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
             }
          }
        ).then(response => {
          setText(text)
          comment.body = text
          setCommentEditActive(false)
          })
          .catch(error => {
            setMessage(error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message)
          })
        }
    else{
        alert('Comment is empty')
    }
  }

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (editRef == null || editRef.current == null || !editRef.current.contains(event.target)){
        setCommentEditActive(false)
      }
    })
  })


  return (
      <div className = "comment-wrapper" ref = {menuRef}>
        <img src = {person} alt = ""/>
        <div className = "comment">
          <h6 onClick = {()=>{navigate(`playlists/${comment.user_id}`)}}>{comment.username}</h6>
          { !commentEditAvtive ?
            <div>
              <div className = "comment-text">
                <div style = {{width:"85%"}}>{comment.body}</div>
              {
                userInfo && userInfo.id === comment.user_id &&
                <div className = "comment-icons">
                  <div className = "editIcon" onClick = {() => setCommentEditActive(true)} >
                    <img src={Edit} alt = "edit"/>
                  </div>
                  <div className = "editIcon" onClick = {() => deleteHandler(comment)}>
                    <img src={Delete} alt = "delete"/>
                  </div>
                </div>
              }
              </div>
            </div> :
            <div className = "editComment" ref = {editRef}>
              {message && <div>{message}</div>}
              <textarea className = "textarea" rows = {3} type = 'text' value = {text} onChange = {(e)=>setText(e.target.value)} required/><br/>
              <div className = "editComment-buttons">
                <div className = "editComment-cancel" onClick = {()=>setCommentEditActive(false)}>Cancel</div>
                <div className = "editComment-save"  onClick = {() => editHandler(comment)}>Save</div>
              </div>
            </div>
          }
        </div>
    </div>
  )
}

export default Comment

