import React, { useState, useEffect, useRef } from 'react'
import AddVideoToPlaylistContent from '../playlistAdds/AddVideoToPlaylistContent'
import ShareContent from './ShareContent'
import './VideoNameAndActions.css'
import Add from '../icons/Add.svg'
import Share from '../icons/Share.svg'
import commentIcon from '../icons/commentIcon.svg'

const VideoNameAndActions = ({userInfo, playlists, activeVideo, playlistVideos, handleReWind, analyzeActive, setPlaylistVideos, activePlaylist, setPlaylists, setCommentActive, commentActive}) => {
  const [isActive, setIsActive] = useState(false)
  const [shareActive, setShareActive] = useState(false)
  let addRef = useRef()

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (addRef == null || addRef.current == null || !addRef.current.contains(event.target)){
        setIsActive(false)
        setShareActive(false)
      }
    })
  })
  
  let members = ''

  if (activeVideo.members){
    for (let i in activeVideo.members){
      members += activeVideo.members[i] += ' '
    }
  }


  return(
    <div className = "video-name-and-action">
      <div className = "analyze-wrapper">
        <div className ={analyzeActive ? "ring animate" : "ring"}>
        </div>
        <div className = "analyze" onClick = {handleReWind}>ANALYZE</div>
      </div>
      <div className = "video-name-members">
        <div className = "video-name-heading" title = {activeVideo.name}>{activeVideo.name}</div>
        <div className = "video-members" title = {members}>{members}</div>
      </div>
        <div className = "actions-wrapper" ref = {addRef}>
          <div className = "add-to-playlist"  onClick={() => setShareActive(!shareActive)}>
            <img src = {Share} alt = "Share"/>
          </div>

          <div className = "add-to-playlist" onClick = {()=>setIsActive(!isActive)}>
            <img src = {Add} alt = "Add"/>
          </div>
          
          {
            !commentActive &&
            <div className = "add-to-playlist">
              <img src = {commentIcon} onClick = {()=>setCommentActive(true)} alt = "Comments"/>
            </div>
          }
          {
            isActive ?
              <AddVideoToPlaylistContent
                setPlaylists = {setPlaylists}
                setIsActive = {setIsActive}
                playlists = {playlists}
                activeVideo = {activeVideo}
                playlistVideos = {playlistVideos}
                setPlaylistVideos = {setPlaylistVideos}
                activePlaylist = {activePlaylist}
                userInfo = {userInfo}
              /> : ''
            }
           {
            shareActive && 
            <ShareContent
              activeVideo = {activeVideo}
            />
           } 
        </div>
    </div>

  )
}

export default VideoNameAndActions
