import '../playlists/Playlists.css'
import Slider from '../slides/Slider'
import React, { useState, useEffect } from 'react';
import axios from 'axios'

export default function RelatedVideo({showAllPath, showAllClickHandler, videoID, sCWHeight, displayCount, slideClickHandler }) {

    const [relatedVideos, setRelatedVideos] = useState([])
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/related/videos/${videoID}/5/`,
        {
          headers:{
            'Content-type':'application/json',
            'Accept': 'application/json'
           }
        }
      )
        .then(res => {
          setRelatedVideos(res.data)
          setLoading(false)
        })
        .catch(message => {
          setMessage(message.response && message.response.data.message
          ? message.response.data.message
          : message.message)
        })
      },[videoID])


  let slideWrapperWidth = 9.5
  let slidePlusMargin = slideWrapperWidth + .6

  return (
    <>
      {
        loading ? <div>Loading...</div> :
        message ? <h3>{message}</h3> :
        relatedVideos && relatedVideos.length ?
        <div className="playlists">
          <Slider
            title = {"RELATED VIDEOS"}
            displayCount={displayCount}
            sliderContentWidth={(slideWrapperWidth + .6) * relatedVideos.length - .6}
            slideWrapperWidth={slideWrapperWidth}
            step={slidePlusMargin}
            sCWHeight={sCWHeight}
            tvData={relatedVideos}
            slideClickHandler={slideClickHandler}
            showAllPath={showAllPath}
            showAllClickHandler={showAllClickHandler}
          />
        </div>
        : ''
      }
    </>
  );
}
