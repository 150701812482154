import React, {useState, useEffect} from 'react'
import axios from 'axios'
import './Playlists.css'
import Slider from '../slides/Slider'


const OtherPlaylists = ({userInfo, displayCount, setAnalyzeActive, setSearchParams, setAnalyzed, setPlaying, setActiveData, setPlaylistVideos, setActiveVideo, slideClickHandler, setActivePlaylist, goHomePlaylist, showAllPath, showAllClickHandler, playlistSlideClickHandler, sCWHeight}) => {

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState()
  const [otherPlaylists, setOtherPlaylists] = useState()

  let config = {}

  if(userInfo){
    config =       {
            headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
             }
          }
  }

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/otherPlaylists/${0}/`,
      config
    )
      .then(res => {
        if (res.data && res.data.length){
          setOtherPlaylists(()=>{ return res.data.sort( () => .5 - Math.random()) })
        } else {
          setMessage('No Playlists Yet')
        }
        setLoading(false)
      })
      .catch(message => {
        setMessage(message.response && message.response.data.message
        ? message.response.data.message
        : message.message)
      })
  },[userInfo])

const playlistClickHandler = (data) => {
  setAnalyzed(false)
  setAnalyzeActive(false)
  axios.get(`${process.env.REACT_APP_API_URL}/memoApp/users/playlistVideos/${data.id}/`)
    .then(res => {
      if (res.data){
        setPlaylistVideos(res.data)
        setActiveData(res.data)
        if(res.data.length){
          setActiveVideo(res.data[0])
          const videoId = data.id
          setSearchParams({videoId})
          setPlaying(true)
        }
        setLoading(false)
      }
    })
    .catch(message => {
      setMessage(message.response && message.response.data.message
      ? message.response.data.message
      : message.message)
    })
  setActivePlaylist(data)
}

  let slideWrapperWidth = 9.5
  let slidePlusMargin = slideWrapperWidth + .6

  return (
    <>
      {
        loading ? <div>Loading...</div> :
        message ? <h3>{message}</h3> :
        otherPlaylists && otherPlaylists.length &&
        <div className = 'playlists'>
          <Slider
            title = {"PLAYLISTS"}
            displayCount={displayCount}
            sliderContentWidth={(slideWrapperWidth + .6) * otherPlaylists.length - .6}
            slideWrapperWidth={slideWrapperWidth}
            step={slidePlusMargin}
            sCWHeight={sCWHeight}
            tvData={otherPlaylists}
            goHomePlaylist={goHomePlaylist}
            showAllPath={showAllPath}
            showAllClickHandler={showAllClickHandler}
            slideClickHandler = {playlistClickHandler}
          />
        </div>
      }
    </>
  )
}
export default OtherPlaylists
