import React from 'react'
import './AddToPlaylist.css'

const Notification = ({message}) => {
    return (
        <div className = "notifications">
            <h6>{message}</h6>
        </div>
    )
}

export default Notification