import React, { useState } from 'react'
import axios from 'axios'
import "./AddPlaylist.css"

const AddPlaylist = ({setPlaylists, setIsActive, playlists, userInfo}) => {

  const [playlistName, setPlaylistName] = useState()
  const [message, setMessage] = useState()


  const config = {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
     }
  }

  const submitHandler = (e) => {
    e.preventDefault()
      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/addPlaylist/`,
      { 'playlistName': playlistName },
      config
    )
      .then(response => {
        if(playlists && playlists.length){
          setPlaylists((prev)=>{return [response.data, ...prev]} )
        } else {
          setPlaylists([response.data])
        }

        setIsActive(false)
      })
      .catch(message => {
        setMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      })
  }


  return (
    <div className = "addPlaylist">
      <div className = "plName-input" >
        <input
          type = "text"
          placeholder = "Name..."
          value = {playlistName}
          onChange = {(e) => setPlaylistName(e.target.value)}
          required
        />
      </div>
      <div className = "create-button">
        <button type = "submit" onClick = {submitHandler} variant = 'primary' className = "create" style = {{width:"8vw"}}>Create</button>
      </div>
    </div>
  )
}

export default AddPlaylist;
