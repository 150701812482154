import React from 'react'
import '../playlists/Playlists.css'
import Slider from '../slides/Slider'

export default function Playlist({showAllPath, activePlaylist, playlistVideos, showAllClickHandler, displayCount, sCWHeight, slideClickHandler}) {

    let slideWrapperWidth = 14.4
    let slidePlusMargin = slideWrapperWidth + .8
    return (
        <>
            {
              // loading ? <div>Loading...</div> :
              // message ? <h3>{message}</h3> :
              playlistVideos && playlistVideos.length ?
                <div className="playlists">
                    <Slider
                        title = {activePlaylist.name}
                        displayCount={displayCount}
                        sliderContentWidth={(slideWrapperWidth + .8) * playlistVideos.length - .8}
                        slideWrapperWidth={slideWrapperWidth}
                        step={slidePlusMargin}
                        sCWHeight={sCWHeight}
                        tvData={playlistVideos}
                        slideClickHandler={slideClickHandler}
                        showAllPath = {showAllPath}
                        showAllClickHandler={showAllClickHandler}
                    />
                </div>
                :  <div className = "playlists"><h3>{activePlaylist.name}</h3><h3 style = {{fontSize:"1.5rem"}} >Playlist is empty</h3></div>
            }
        </>
    );
}
