import React, {useState, useEffect, useRef} from 'react'
import './Header.css'

const SearchContent = ({setSearchValue, setKeyWord, setIsSearchActive, currentResults, isSearchActive, searchValue}) => {

  let searchRef = useRef()

  // chooses a value from suggested results from currentSearch results
  const chooseHandler = (value) => {
      setSearchValue(value.name)
      setKeyWord(value.name)
      setIsSearchActive(false)
  }

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (searchRef == null || searchRef.current == null || !searchRef.current.contains(event.target)){
        setIsSearchActive(false)
      }
    })
  })

  return (
    isSearchActive && searchValue && searchValue.length && currentResults && currentResults.length ?
      <div className = "addSearchDropdown" ref = {searchRef}>
        <div className = "addSearchDropdown-content">
          {
            currentResults.map((result) => (
                <div
                  className = "addSearchDropdown-item"
                  key = {result.id}
                  onClick = {()=>chooseHandler(result)}
                >{result.name}</div>
            ))
          }
        </div>
      </div> : ''
  )
}

export default SearchContent
