import React, {useState, useEffect} from 'react'
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import './AddToPlaylist.css'
import Notification from './Notification'
import axios from 'axios'
import but from '../icons/but.svg'
import ON from '../icons/ON.png'


const AddVideoToPlaylistList = ({playlists, activeVideo, setPlaylistVideos, activePlaylist, setPlaylists }) => {

  const [message, setMessage] = useState()
  const [playlistIncludeIds, setPlaylistIncludeIds] = useState()

  const config = {
    headers:{
      'Content-type':'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
     }
  }


  //finds the ids of playlists, in which the video already exists
  useEffect(()=>{
    if(activeVideo){
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/checkPlaylist/${activeVideo.id}/`,
        config
      ).then(res =>
        {
          setPlaylistIncludeIds(res.data)
        }
      ).catch (error =>
        console.log(error)
      )
    }
  },[])


  const clickHandler = (playlist) => {
    if(!playlistIncludeIds.includes(playlist.id))
        {
          axios.get(`${process.env.REACT_APP_API_URL}/memoApp/addPlaylistVideo/${activeVideo.id}/${playlist.id}/`,
            config
          ).then(res =>
            {
              setPlaylistIncludeIds((prevArr)=>[playlist.id, ...prevArr] )
              if (activePlaylist && playlist.id === activePlaylist.id){
                setPlaylistVideos(prevArr => [res.data, ...prevArr])
              }
              playlist.number_of_songs += 1
              playlist.thumbnail = activeVideo.thumbnail
              setPlaylists([...playlists])
              Store.addNotification({
                content: <Notification message = {"Video has been added to playlist"}/>,
                type: 'custom',
                container: "bottom-left",
                insert: "top",
                dismiss: {
                  duration: 2000,
                  pauseOnHover: true
                }
              })
            }
          ).catch(message =>{
            setMessage(message.response && message.response.data.detail
            ? message.response.data.detail
            : message.message)
          }
          )
        }
    else
        {
          axios.delete(`${process.env.REACT_APP_API_URL}/memoApp/deletePlaylistVideo/${playlist.id}/${activeVideo.id}/`,
            config
            ).then(res => {
                setPlaylistIncludeIds((prevArr) => prevArr.filter(item => item !== playlist.id));
                if (activePlaylist && playlist.id === activePlaylist.id){
                  setPlaylistVideos((prevArr) => prevArr.filter(item => item.id !== activeVideo.id))
                }
                playlist.number_of_songs -= 1
                playlist.thumbnail = res.data.thumbnail
                setPlaylists([...playlists])
                Store.addNotification({
                  content: <Notification message = {"Video has been deleted from playlist"}/>,
                  type: 'custom',
                  container: "bottom-left",
                  insert: "top",
                  dismiss: {
                    duration: 2000,
                    pauseOnHover: true
                  },
                  width: 300
                })
              })
              .catch(message =>{
                setMessage(message.response && message.response.data.detail
                ? message.response.data.detail
                : message.message)
              }
            )

        }
  }

  return (
    <>
      <div className = "addToplaylist-list-wrapper" >
        {
            playlists && playlists.map(playlist =>(
              <div
                className = "plList-element"
                key = {playlist.id}
                id = {playlist.id}
                name = {playlist.name}
                onClick = {()=>{clickHandler(playlist)}}
              >
                <div style = {{
                  position: "relative",
                  left: "0",
                  top: "-0.6vw"
                }}>
                  {
                    playlistIncludeIds && playlistIncludeIds.length && playlistIncludeIds.includes(playlist.id) ? <img src = {ON} alt = "" className = "unchecked"/> : <img src = {but} alt = "" className = "unchecked"/>
                  }
                </div>
                <h6>{playlist.name}</h6>
              </div>
          ))
        }
      </div>
    </>
  )
}

export default AddVideoToPlaylistList
