import React, { useEffect, useRef, useState} from 'react'
import './Header.css'
import search from '../icons/search.svg'
import filter from '../icons/filter.svg'
import SearchContent from './SearchContent'
import FilterContent from './FilterContent'
import axios from 'axios'


const SearchComponent = ({setFilterId, setKeyWord, startSearch}) => {
  // when filter is active, filter menu is visible
  const [activeFilter, setActiveFilter] = useState(false)
  // shows on screen (only on screen) a component, according which searches results, e.g mood, artist etc..
  const [filterBy, setFilterBy] = useState('All')
  // sends searchID in back, to specify, which search component to use
  const [searchId, setSearchId] = useState(0)
  // value we type in search input
  const [searchValue, setSearchValue] = useState('')
  //when active, shows suggested results according to search value
  const [isSearchActive, setIsSearchActive] = useState(false)
  // suggested results in process of typing in search input
  const [currentResults, setCurrentResults] = useState([])
  const [moodGenreID, setMoodGenreID] = useState(0)

  let menuRef = useRef()


  // activates/deactivates filter menu
  const filterClickHandler = (e) =>{
    e.preventDefault()
    setActiveFilter(true)
  }


// sends data to searchResult component for ultimate search results
  const searchHandler = (e) => {
    setKeyWord('')
    setFilterId('')
    if (searchValue.length && searchValue !== searchValue.length*' '){
      startSearch(searchId, searchValue, moodGenreID)
    }
  }


// does the same as searchHandler, but with pressing Enter
  const enterHandler = (e) => {
    if(e.key === "Enter"){
      searchHandler(e)
    }
  }



// changes current search results with typing new symbols in search input
  const changeHandler = (e) => {
    e.preventDefault()
    if(!isSearchActive){
      setIsSearchActive(true)
    }
    setSearchValue(e.target.value)
    setCurrentResults([])
    if(e.target.value.length > 2){
      axios.get(`${process.env.REACT_APP_API_URL}/memoApp/currentSearch/${e.target.value}/${searchId}/`,
        { 'limit': 5, 'keyWord': e.target.value, 'filterId' : searchId }
      ).then(res => {
        setCurrentResults(res.data)
      })
      .catch(error => {
        console.log(error)
      })
    }

  }


  return (
    <div className = 'search-relation'>
      <div className="search-wrapper">
        <input
          type="text"
          className="site-search"
          placeholder="Search"
          value = {searchValue}
          onChange = {changeHandler}
          onKeyPress = {enterHandler}
        />
        <div id = "filter-button" onClick = {filterClickHandler} ref = {menuRef}>
          <div id = "filter-img-and-text">
            <img src={filter} alt="filter icon" id="filter-icon-img"/>
            <span>{filterBy}</span>
          </div>
        </div>
        { activeFilter &&
          <FilterContent
            startSearch = {startSearch}
            setSearchId = {setSearchId}
            setFilterBy = {setFilterBy}
            setActiveFilter = {setActiveFilter}
          />
        }
          <div id = "search-button" onClick = {searchHandler}>
            <div id = "search-icon">
            <img src={search} alt="search icon" id="search-icon-img" />
            </div>
          </div>
      </div>
        <SearchContent
          setSearchValue = {setSearchValue}
          setKeyWord = {setKeyWord}
          setIsSearchActive = {setIsSearchActive}
          currentResults = {currentResults}
          searchValue = {searchValue}
          isSearchActive = {isSearchActive}
        />
    </div>
  )
}

export default SearchComponent
