import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import '../AllVideos.css'
import PlBack from '../icons/PlBack.svg'
import axios from 'axios'
import Slide from '../slides/Slide'
import VideoInfo from '../slides/VideoInfo'
import Footer from '../header_footer/Footer'

const AllRelatedVideos = ({slideClickHandler, displayCount, dataHeight, goBack})=> {

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const [allRelatedVideos, setAllRelatedVideos] = useState()
  const [page, setPage] = useState(1)

  const { videoID } = useParams()

  const handleScroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if(scrollHeight - scrollTop === clientHeight){
      setPage(prev => prev + 1)
    }
  }

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/related/videos/${videoID}/${page}/`)
      .then(res => {
        if (page > 1){
          setAllRelatedVideos(prev => [...prev, ...res.data])
        } else {
          setAllRelatedVideos(res.data)
        }
        setLoading(false)
      })
      .catch(error => {
        setMessage(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message)
      })
    },[videoID])



    return (
      <div className = "allVideos-wrapper">
          <div className="all-playlist-videos">
              <div className="show-all-heading">
                  <h6>RELATED VIDEOS</h6>
                  <div className='sah-icons-wrapper'>
                      <div className="icon-wrapper" onClick={() => { goBack() }}> <img src={PlBack} alt="go back" /> </div>
                  </div>
              </div>
              <div className="all-playlist-videos-content" onScroll = {handleScroll}>
                  {
                    loading ? <div>Loading...</div> :
                    message ? <h3>{message}</h3> :
                    allRelatedVideos && allRelatedVideos.length ?
                      allRelatedVideos.map(
                          (myVideo, i) =>
                              <div
                                  className={'single-video-wrapper'}
                                  key={i}
                              >
                                  <div className="single-video-bg"
                                      // style={{ height: dataHeight + 'vw' }}
                                      // onClick={() => { if (slideClickHandler) slideClickHandler(myVideo); goBack() }}
                                  >
                                      <Slide
                                        content={myVideo}
                                        displayCount={displayCount}
                                        // onClick={() => { if (slideClickHandler) slideClickHandler(myVideo); goBack() }}
                                        slideClickHandler = {slideClickHandler}
                                        width = {"9vw"}
                                        />
                                  </div>
                                  <div className = "name-edit-delete">
                                    <div style = {{width: "65%"}}>
                                      <VideoInfo data = {myVideo}/>
                                    </div>
                                  </div>
                              </div>
                      ) : ''
                  }
              </div>
          </div>
          <Footer />
      </div>
    )
}

export default AllRelatedVideos
