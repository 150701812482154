import React, {useState, useEffect} from 'react'
import '../AllVideos.css'
import axios from 'axios'
import Slide from '../slides/Slide'
import VideoInfo from '../slides/VideoInfo'
import goBackIcon from '../icons/PlBack.svg'
import Footer from '../header_footer/Footer'


const AllMonthPopular = ({slideClickHandler, displayCount, dataHeight, goBack})=> {

  const [allMonthPopularVideos, setAllMonthPopularVideos] = useState([])
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)


  const handleScroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if(scrollHeight - scrollTop === clientHeight){
      setPage(prev => prev + 1)
    }
  }


  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/memoApp/monthPopular/${page}/`,
      ).then(res => {
          if (page > 1){
            setAllMonthPopularVideos(prev => [...prev, res.data])
          } else {
            setAllMonthPopularVideos(res.data)
          }
          setLoading(false)
        })
        .catch(error => {
          setMessage(error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message)
        })
  },[page])


  return (
    <div className = "allVideos-wrapper">
          <div className="all-playlist-videos">
              <div className="show-all-heading">
                  <h6>POPULR THIS MONTH</h6>
                  <div className='sah-icons-wrapper'>
                      <div className="icon-wrapper" onClick={() => { goBack() }}> <img src={goBackIcon} alt="go back" /> </div>
                  </div>
              </div>
              <div className="all-playlist-videos-content" onScroll = {handleScroll}>
                  {
                    loading ? <div>Loading...</div> :
                    message ? <h3>{message}</h3> :
                    allMonthPopularVideos && allMonthPopularVideos.length ?
                      allMonthPopularVideos.map(
                          (myVideo, i) =>
                              <div
                                  className={'single-video-wrapper'}
                                  key={i}
                              >
                                  <div className="single-video-bg"
                                      style={{ height: dataHeight + 'vw' }}
                                  >
                                      <Slide
                                        content={myVideo}
                                        displayCount={displayCount}
                                        slideClickHandler = {slideClickHandler}
                                        width = {"9vw"}
                                        />
                                  </div>
                                  <div className = "name-edit-delete">
                                    <div style = {{width: "65%"}}>
                                      <VideoInfo data = {myVideo}/>
                                    </div>
                                  </div>  
                              </div>
                      ) : ''
                  }
              </div>
          </div>
          <Footer />
      </div>
  )
}

export default AllMonthPopular
