import './Slider.css'
import React, { useEffect, useRef, useState } from 'react'
import SliderContent from './SliderContent'
import Arrow from './Arrow'
import ShowAll from '../icons/Showall.svg'

export default function Slider({title, showAllPath, showAllClickHandler, displayCount, sliderContentWidth, slideWrapperWidth, step, sCWHeight, tvData, slideClickHandler, playlistSlideClickHandler, updateActivePlaylistId, goHomePlaylist }) {
  let extraSlides = tvData.length - displayCount
  const [movedAndMove, setMovedAndMove] = useState([0, 0])

  const sCWRef = useRef(null)

  let isDown = false
  let startX

  const handleSCWMouseDown = (e) => {
    e.preventDefault()
    isDown = true
    startX = e.clientX
  }
  const handleSCWMouseUp = (e) => {
    isDown = false
  }
  const handleSCWMouseLeave = (e) => {
    isDown = false
  }
  const handleSCWMouseMove = (e) => {
    e.preventDefault()
    if (!isDown) return
    let endX = e.clientX
    let scroll = endX - startX
    if (scroll === 0) {
      return
    } else if (scroll > 1) {
      isDown = false
      prevSlide()
    } else if (scroll < 1) {
      isDown = false
      nextSlide()
    }
  }
  useEffect(() => {
    const element = sCWRef.current
    element.addEventListener('mousedown', handleSCWMouseDown)
    element.addEventListener('mouseup', handleSCWMouseUp)
    element.addEventListener('mouseleave', handleSCWMouseLeave)
    element.addEventListener('mousemove', handleSCWMouseMove)
    return function cleanUp() {
      element.removeEventListener('mousedown', handleSCWMouseDown)
      element.removeEventListener('mouseup', handleSCWMouseUp)
      element.removeEventListener('mouseleave', handleSCWMouseLeave)
      element.removeEventListener('mousemove', handleSCWMouseMove)
    }
  })

  useEffect(() => {
    setMovedAndMove([0, 0])
  }, [tvData])

  const prevSlide = () => {
    if (extraSlides > 0) {
      if (movedAndMove[0] === 0) {
        setMovedAndMove([extraSlides, extraSlides * step])
      } else {
        setMovedAndMove([movedAndMove[0] - 1, movedAndMove[1] - step])
      }
    }
  }

  const nextSlide = () => {
    if (extraSlides > 0) {
      if (movedAndMove[0] === extraSlides) {
        setMovedAndMove([0, 0])
      } else {
        setMovedAndMove([movedAndMove[0] + 1, movedAndMove[1] + step])
      }
    }
  }


  return (
    <>
      <div className="slider" style={{ position: 'relative' }}>
      <div className = "title-and-ShowAll">
        <h3>{title}</h3>
        <div className="show-all-wrapper">
          <div className = "arrows">
            <Arrow direction='back' handleClick={prevSlide} />
            <Arrow direction='forward' handleClick={nextSlide} />
          </div>
          <div className="show-all" onClick={() => showAllClickHandler && showAllClickHandler(showAllPath)}>
            <img src={ShowAll} alt="show all icon" id="show-all-img" style={{height:"1.15vw"}} />
          </div>
        </div>
      </div>
        <div
          ref={sCWRef}
          className='slider-content-wrapper'
          style={{
            height: `${sCWHeight}vw`,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <SliderContent
            displayCount={displayCount}
            translate={movedAndMove[1]}
            sliderContentWidth={sliderContentWidth}
            slideWrapperWidth={slideWrapperWidth}
            tvData={tvData}
            slideClickHandler={slideClickHandler}
            playlistSlideClickHandler={playlistSlideClickHandler}
            updateActivePlaylistId={updateActivePlaylistId}
            goHomePlaylist={goHomePlaylist}
          />
        </div>
      </div>
    </>
  );
}
