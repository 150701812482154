import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Slides.css'

const VideoInfo = ({data}) => {

  let members = ''
  let navigate = useNavigate()

  if (data.members){
    for (let i in data.members){
      members += data.members[i]
    }
  }

  return (
    <div className = "videoInfo">
     <h6 title={data.name}>{data.name}</h6>
     {data.members ? <h6 title={data.members}>{members}</h6> : '' }
     {data.username && <div style = {{cursor: "pointer"}} onClick={()=>navigate(`/playlists/${data.user_id}`)} ><h6 style = {{"color":"#c3f"}} title={data.username}>@{data.username}</h6></div>}
     {data.number_of_songs ? <h6>{data.number_of_songs} videos</h6> : '' }
     { data.number_of_songs === 0 && <h6>No videos</h6>}
    </div>
  )
}

export default VideoInfo

