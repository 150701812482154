import React from 'react'
import apple from "../icons/Apple.svg"
import deezer from "../icons/Deezeer.svg"
import pandora from "../icons/Pandora.svg"
import spotify from "../icons/Spotify.svg"
import amazon from "../icons/Amazon.svg"
import cuview from "../icons/Cugate.svg"
import BlueEye from '../icons/BlueEye.png'
import Copy from '../icons/Copy.png'
import M from '../icons/M.svg'
import './CuAnalyze.css'


const AnalyzeResults = ({data}) => {

    const openTab =(member) => {
        if (member.url && member.url !== '#' ){
            window.open(member.url);
        }
    }


    const iconClickHandler = (lnk) =>{
        window.open(lnk.url)
    }

    const MoveToVideoLink = (lnk) => {
        if (lnk !== '#'){
            window.open(lnk)
        }
    }
 
console.log(data)

    return (
        data && data.track_id  ?
        <div className = "analyzeRezults">
          <img src = {data.album.cover_url} alt = 'img'/>
            <div className = "analyzeContent">
                <div>
                    {
                        data.track_part ? 
                        <div className = "analyzeInfo">
                            <div onClick = {() => MoveToVideoLink(data.track_url)} style = {{cursor:"pointer"}}>{data.track_title + ' / ' +data.track_part}
                                {
                                    data.track_id !== -1 &&  <img src = {BlueEye}  onClick = {()=>{window.open(`https://te.cugate.com/?act=track_culink&ref=tracks&ids=${data.track_id}%3B&tracksnum=1&album_id=${data.album.id}&link_type=Extra%20Link`)}}/>
                                }
                            </div>
                       </div>
                         : 
                         <div  className = "analyzeInfo">
                            <div>{data.track_title}
                                {
                                    data.track_id !== -1 &&  <img src = {BlueEye}  onClick = {()=>{window.open(`https://te.cugate.com/?act=track_culink&ref=tracks&ids=${data.track_id}%3B&tracksnum=1&album_id=${data.album.id}&link_type=Extra%20Link`)}}/>
                                }
                            </div>
                        </div>
                    }
                    <div className = "analyzeMembers">
                        {
                            data.members && data.members.length ?
                            data.members.map((member)=>(
                                <div className = "analyzeInfo"
                                        key = {member.id}
                                        onClick = {()=>openTab(member)}
                                    >{member.title2}
                                    <img src = {M}  onClick = {()=>{window.open(member.merch_url)}}/>
                                    {
                                        member.ticket_events && member.ticket_events.length ?
                                        <img src = {Copy} onClick = {()=>{window.open(`https://www.cumarket.net/member_ticket_modal.php?member_id=${member.id}&user_lat=41.7149921&user_lng=44.762258`)}}/>:"" 
                                    }
                                </div>
                            )) : ''
                        }
                    </div>
                </div>
                <div className='analyzeIcons'>
                    <img src = {apple} alt = "apple" onClick = {() => iconClickHandler(data.culinks.ITUNES)}/>
                    <img src = {deezer} alt = "deezer"  onClick = {() => iconClickHandler(data.culinks.DEEZER)}/>
                    <img src = {pandora} alt = "pandora" onClick = {() => iconClickHandler(data.culinks.RDIO)}/>
                    <img src = {spotify} alt = "spotify" onClick = {() => iconClickHandler(data.culinks.SPOTIFY)}/>
                    <img src = {amazon} alt = "amazon" onClick = {() => iconClickHandler(data.culinks.AMAZON)}/>
                    <img src = {cuview} alt = "cuview" onClick = {() => iconClickHandler(data.culinks.CUMARKET)} />
                </div>
            </div>
        </div> : ''
    ) 
}

export default AnalyzeResults