import React from 'react'
import './VideoNameAndActions.css'
import {
    EmailShareButton,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon
  } from "react-share";


const ShareContent = ({activeVideo}) => {

    const url = `https://new.memolandtv.com/memoApp/share/${activeVideo.id}/`
    
    return (
        <div className = "shareMenu">
            <div className = "share-icon">
                <FacebookShareButton url = {url} quote = {activeVideo.name}>
                    <FacebookIcon round = {true} size = {"2.3vw"}></FacebookIcon>
                </FacebookShareButton>
            </div>
            <div className = "share-icon">
                <PinterestShareButton url = {url} media = {activeVideo.thumbnail}>
                    <PinterestIcon round = {true} size = {"2.3vw"}></PinterestIcon>
                </PinterestShareButton>
            </div>
            <div className = "share-icon">
                <TwitterShareButton url = {url} title = {activeVideo.name}>
                    <TwitterIcon round = {true} size = {"2.3vw"}></TwitterIcon>
                </TwitterShareButton>
            </div>
            <div className = "share-icon">
                <ViberShareButton url = {url} title = {activeVideo.name}>
                    <ViberIcon round = {true} size = {"2.3vw"}></ViberIcon>
                </ViberShareButton>
            </div>
            <div className = "share-icon">
                <LinkedinShareButton url = {url} title = {activeVideo.name}>
                    <LinkedinIcon round = {true} size = {"2.3vw"}></LinkedinIcon>
                </LinkedinShareButton>
            </div>
            <div className = "share-icon">
                <WhatsappShareButton url = {url} title = {activeVideo.name}>
                    <WhatsappIcon round = {true} size = {"2.3vw"}></WhatsappIcon>
                </WhatsappShareButton>
            </div>
            <div className = "share-icon">
                <EmailShareButton url = {url} subject = {activeVideo.name}>
                    <EmailIcon round = {true} size = {"2.3vw"}></EmailIcon>
                </EmailShareButton>
            </div>
        </div>
    )
}

export default ShareContent


            /* <div className = "share-icon">
                <FacebookMessengerShareButton>
                    <FacebookMessengerIcon></FacebookMessengerIcon>
                </FacebookM3essengerShareButton>
            </div> */