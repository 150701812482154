import '../playlists/Playlists.css'
import React, { useState, useEffect } from 'react';
import Slider from '../slides/Slider';
import axios from 'axios'

export default function LatestVideo({displayCount, slideClickHandler, filteredLatestVideos, setLatestVideos, latestVideos, setFilteredLatestVideos, showAllPath, sCWHeight, showAllClickHandler }) {

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true)

  let activeGenres = []

  useEffect(()=>{
    axios.post(`${process.env.REACT_APP_API_URL}/memoApp/latest/videos/${0}/`,
      { 'genres': activeGenres },
        {
          headers:{
            'Content-type':'application/json',
            'Accept': 'application/json'
           }
        }
    ).then(res => {
      setLatestVideos(res.data)
      activeGenres = JSON.parse(localStorage.getItem('activeGenres'))
      setFilteredLatestVideos(()=>{return (res.data.filter(video => activeGenres && activeGenres.includes(video.genre))).sort( () => .5 - Math.random()) })
      setLoading(false)
    })
    .catch(error => {
      setMessage(error.response && error.response.data.detail
      ? error.response.data.detail
      : error.message)
    })
  },[])

  let slideWrapperWidth = 14.4
  let slidePlusMargin = slideWrapperWidth + .8



  return (
    <div className="playlists">
      {
        loading ? <div>Loading...</div> :
        message ? <h3>{message}</h3> :
        filteredLatestVideos && filteredLatestVideos.length ?
        <>
          <Slider
              title = {"LATEST VIDEOS"}
              displayCount={displayCount}
              sliderContentWidth={(slideWrapperWidth + .8) * filteredLatestVideos.length - .8}
              slideWrapperWidth={slideWrapperWidth}
              step={slidePlusMargin}
              sCWHeight={sCWHeight}
              tvData={filteredLatestVideos}
              slideClickHandler={slideClickHandler}
              showAllPath={showAllPath}
              showAllClickHandler={showAllClickHandler}
          />
        </> : <h3>You have not chosen Genre</h3>
      }
    </div>
  )
}
